import React, { useState, useEffect } from "react";
import {
  getUserRoles,
  getAllPermissions,
  rolePermissionStore,
  getRolePermissions,
} from "../services/PermissionAPIServices";
import Swal from "sweetalert2";
import $ from "jquery";

function Permission() {
  const [roleList, setRoleList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [user_role, setUserRole] = useState("");
  const spinner = document.getElementById("spinner");

  useEffect(() => {
    getUserRoles().then((response) => {
      spinner.style.display = "none";
      setRoleList(response);
    });

    getAllPermissions().then((response) => {
      setMenuList(response);
    });
  }, []);

  const handleRoleChange = (e) => {
    const value = e.target.value;
    setUserRole(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selected_permissions = [];
    $("#checkboxes input:checked").each(function () {
      var check = selected_permissions.includes($(this).attr("id"));
      if (check) {
      } else {
        selected_permissions.push($(this).attr("id"));
      }
    });

    // console.log(selected_permissions);
    if(selected_permissions.length == 0 || user_role == "" || user_role == null ){
      if(user_role == "" || user_role == null){
        Swal.fire("warning", "Please select user role", "warning");
      }else{
        Swal.fire("warning", " Please select at least one permission module", "warning");
       
      }
    }else{
      const data = { user_role, selected_permissions };
      rolePermissionStore(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("success", "" + response.message, "success");
          $("input[type=checkbox]").prop("checked", false);
          setUserRole("");
        //   setTimeout(function(){
        //     window.location.reload();
        //  }, 1500);
        } else {
          Swal.fire("warning", " " + response.message, "warning");
        }
      });
    }

  };

  function changeDataLoad(event) {
    $("input[type=checkbox]").prop("checked", false);
    const role = {
      user_role,
    };
    if(user_role == "" || user_role == null){
      Swal.fire("warning", "Please select user role", "warning");
    }else{

      getRolePermissions(role).then((response) => {
        if (response.status_code == 1) {
          $.each(response.data.all_accesses, function (k, v) {
            const checkid = v.permission_id;
  
            $("#" + checkid).prop("checked", true);
          });
        }
      });
    }


  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Permission</h3>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3">
            <label for="form-action">
              User Role
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              id="user_role"
              name="user_role"
              className="form-control"
              value={user_role}
              onChange={handleRoleChange}
              
            >
              <option value="" disabled>
                Select user role
              </option>
              {roleList.map((item, i) => {
                return (
                  <option value={item.id} key={i}>
                    {" "}
                    {item.role_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <label for="users-list-role" style={{ color: "white" }}>
              .....
            </label>
            <button
              type="button"
              className="btn btn-primary btn-block users-list-clear glow mb-0"
              onClick={changeDataLoad}
            >
              <i className="ft-search"></i> Check Permissions
            </button>
          </div>
        </div>
        <div class="col-12 users-module">
          <div id="checkboxes">
            <div class="table-responsive">
              <table class="table mb-0 mt-3">
                <thead>
                  <tr>
                    <th>Module Permission</th>
                    <th>Access</th>
                  </tr>
                </thead>

                <tbody>
                  {menuList.map((menu, i) => {
                    return (
                      <tr>
                        <td className="d-flex align-items-center">  {menu.is_main_menu == 1 ? (<>
                          <b><i class={`${menu.icon}`} style={{fontWeight:600,fontSize:'20px'}}></i> {menu.permission_name}</b>
                        </>):(<>
                          &emsp; <i class={`${menu.icon}`} style={{fontSize:'20px'}}></i> {menu.permission_name}
                        </>)}</td>
                        <td>
                          <label className="checkbox-container">
                          <input
                            type="checkbox"
                            value={menu.permission_id}
                            id={menu.permission_id}
                            name={menu.permission_id}
                            defaultChecked={false}
                          />
                           <span class="checkmark"></span>
                          </label>
                        
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
            <button
              type="submit"
              className="btn btn-primary mb-2 mb-sm-0 mr-sm-2"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
export default Permission;
