import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Select, Table, Switch, Modal, Radio, Checkbox } from "antd";
import { createNewQuiz, getAllQuizList, getNotifySetting, getQuizAllQuestionAndAnswers, quizPublishToEmployee, submitQuizQAndA, submitSettingDetails } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import {
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import QuestionAndAnwserCard from "../QuestionAndAnwserCard";

function AlcoholTrainingSetting({ id }) {

  const { Option } = Select;
  const [completeDays, setCompleteDays] = useState("");
  const [schedule_duration, setScheduleDuration] = useState("");
  const [quizList, setQuizList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add New Quiz");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [title, setQuizTitle] = useState("");
  const [description, setQuizDescription] = useState("");
  const [total_questions, setQuizTotalQuestion] = useState("");
  const [questionModalTitle, setQuestionModalTitle] = useState("");
  const [questionMVisible, setQuestionMVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [questionAndAnswser, setQuestionAndAnswser] = useState([]);
  const [selectedQuizId, setSelectedQuizId] = useState("");
  const [newQuestion, setNewQuestion] = useState("");
  const [newQuestionType, setNewQuestionType] = useState("");
  const [answerModalTitle, setAnswerModalTitle] = useState("");
  const [answerMVisible, setAnswerMVisible] = useState(false);
  const [newAnswer, setNewAnswer] = useState("");
  const [newAnswerIsCorrect, setNewAnswerIsCorrect] = useState(false);
  const [newAnswersArray, setNewAnswersArray] = useState([]);
  let [answerArrayCount, setAnswerArrayCount] = useState(0);
  const [totalQuestionCount, setTotalQuestionCount] = useState("0");

  const columns = [
    {
      key: "title",
      title: "Title",
      dataIndex: "title"
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description"
    },
    {
      key: "question_count",
      title: "Added Question / Total Count",
      render: (record) => {
        return (
          <>
            <span>{record.added_questions} / {record.total_questions}</span>
          </>
        );
      },
    },
    {
      key: "is_published",
      title: "Published",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              {record.is_published == "0" ? (
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultUnChecked
                  onClick={() => quizPublishFunction(record.id)}
                />
              ) : (
                <span className="badge badge-success">Yes</span>
              )}

            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info ml-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => handleQuestionModal(record, 'view')}
              >
                <i className="ft-eye"></i> View
              </button>
              {record.is_published == "0" ? (
                <button
                  className="badge badge-pill bg-light-danger ml-2"
                  style={{ border: '2px solid #dadada' }}
                  onClick={() => handleQuestionModal(record, 'edit')}
                >
                  <i className="ft-plus"></i>Question
                </button>
              ) : (
                <></>
              )}

            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadSettingsData();
    loadQuizTableData();
  }, []);

  useEffect(() => {
    loadSettingsData();
  }, []);

  const loadSettingsData = () => {
    getNotifySetting(id).then((response) => {

      let notifyData = JSON.parse(response.data.data.notify_settings);

      let result = Object.entries(notifyData).map(([k, v]) => ({ [k]: v }));
      result.forEach((item) => {
        var key = Object.keys(item)[0];
        setCompleteDays(item[key].complete_days);
        setScheduleDuration(item[key].schedule_duration)

      });
    }).catch((error) => { console.error(error) });
  }

  const loadQuizTableData = () => {
    setLoading(true);

    setQuizList([]);
    getAllQuizList().then((response) => {
      setQuizList(response);
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  const loadQuizQAndAData = (quizID) => {

    getQuizAllQuestionAndAnswers(quizID).then((response) => {
      setQuestionAndAnswser(response);
    }).catch((error) => { console.error(error) });
  }

  const quizPublishFunction = (quizId) => {
    const data_json = { quiz_id: quizId }
    quizPublishToEmployee(data_json).then((response) => {
      loadQuizTableData();
      if (response.data.status_code == 1) {
        Swal.fire("Success", "" + response.data.message, "success");
      } else {
        Swal.fire("Warning", " " + response.data.message, "warning");
      }

    }).catch((error) => { console.error(error) });
  }
  function changeDays(value) {
    setCompleteDays(value);
  }

  function handleChangeDuration(value) {
    setScheduleDuration(value);
  }


  const submitSettings = (e) => {


    if (schedule_duration == "") {
      Swal.fire("Warning", "Please select schedule duration.", "warning");
    } else if (completeDays == "") {
      Swal.fire("Warning", "Please select complete days.", "warning");
    } else {

      var json_string = '{"0":{"schedule_duration":"' + schedule_duration + '","complete_days":"' + completeDays + '"}}';

      const data_json = { compliance_type: id, notify_settings: json_string }
      submitSettingDetails(data_json).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadSettingsData();
          });
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });
    }
  }

  const handleNewLogModal = () => {
    setConfirmLoading(false);
    setQuizTitle("");
    setQuizDescription("");
    setQuizTotalQuestion("");
    setModalTitle("Add New Quiz");
    setVisible(true);
  };


  const handleSubmit = (e) => {
    const dataset = { title, description, total_questions }
    setConfirmLoading(true);
    createNewQuiz(dataset).then((response) => {

      if (response.data.status_code == 1) {
        Swal.fire("Success", "" + response.data.message, "success").then(function () {
          setConfirmLoading(false);
          loadQuizTableData();
          setVisible(false);
        });
      } else {
        setConfirmLoading(false);
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
    }).catch((error) => {
      setConfirmLoading(false);
      Swal.fire("Warning", "" + error.response.data.message, "warning");
    });
  }

  const handleQuestionModal = (data, action) => {
    setSelectedQuizId(data.id);
    setQuestionModalTitle(data.title + " - Q & A");
    setNewQuestion("");
    setNewQuestionType("");
    setTotalQuestionCount(data.total_questions);
    action == 'edit' ? setIsEditing(true) : setIsEditing(false);
    loadQuizQAndAData(data.id)


    setQuestionMVisible(true);
  };


  const handleNewAnswerModal = () => {
    let processModal = false;
    let validation = false;
    if (totalQuestionCount == questionAndAnswser.length) {
      Swal.fire("Warning", "Quiz total question count filled.", "warning")
    } else {
      newQuestionType == "" && newQuestion == "" ? validation = false : validation = true;
      newQuestionType == "MS" || newQuestionType == 'MCQ' && validation ? processModal = true : processModal = false;

      if (processModal && validation) {
        setAnswerMVisible(true);
        setAnswerModalTitle(questionModalTitle);
      } else {
        !validation ?
          Swal.fire("Warning", "Please fill the question details", "warning") :
          validation && !processModal ?
            submitQuestionAndAnswers() : Swal.fire("Warning", "Please fill the question details", "warning");
      }
    }

  };

  const onChangeIsCorrect = (e) => {
    setNewAnswerIsCorrect(!newAnswerIsCorrect)
  }

  const refreashAnswerDetails = () => {
    setAnswerMVisible(false);
    setConfirmLoading(false);
    setNewAnswersArray([]);
    setNewAnswer("");
    setNewAnswerIsCorrect("");
  }

  const submitQuestionAndAnswers = (e) => {
    let finalValidation = false;
    let validationMsg = "";
    let answerData = "";
    if (newQuestionType != "T") {
      // check ms answer count and MCQ answer count
      if (newAnswersArray.length == 0) {
        validationMsg = "Please add answer options"
        finalValidation = false;
      } else {
        let correct_count = 0;
        for (var ind = 0; ind < newAnswersArray.length; ind++) {

          newAnswersArray[ind]['is_correct'] == 1 ? correct_count++ : '';
        }
        if ((newQuestionType == 'MCQ' && correct_count > 1) || (newQuestionType == 'MCQ' && correct_count == 0)) {
          finalValidation = false;
          validationMsg = "Single selection type question must have one correct answer";
        } else if ((newQuestionType == 'MS' && correct_count < 2) || (newQuestionType == 'MS' && correct_count == 0)) {
          finalValidation = false;
          validationMsg = "Multiple selection type question must have two or more correct answer";
        } else {
          finalValidation = true;
          answerData = newAnswersArray;
        }
      }

    } else {
      finalValidation = true;
    }

    if (finalValidation) {

      const dataset = { quiz_id: selectedQuizId, question: newQuestion, question_type: newQuestionType, answers_list: answerData }
      submitQuizQAndA(dataset).then((response) => {

        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadQuizQAndAData(selectedQuizId);
            setNewQuestion("");
            setNewQuestionType("");
            loadQuizTableData();
            refreashAnswerDetails()

          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });

    } else {
      Swal.fire("Warning", "" + validationMsg, "warning");
    }
  }

  const addAnswers = (e) => {
    let addProcess = false;
    newAnswer != "" ? addProcess = true : addProcess = false;

    if (addProcess) {

      setAnswerArrayCount(answerArrayCount + 1)

      setNewAnswersArray(prevItems => [...prevItems, { "id": answerArrayCount, "answer_text": newAnswer, "is_correct": newAnswerIsCorrect ? '1' : '0' }])
      setNewAnswer("");
      setNewAnswerIsCorrect(false);


    } else {
      Swal.fire("Warning", "Please fill the answer", "warning");
    }

  }
  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12" ><h5>Schedule Notification Settings</h5></div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" >
                        <div className="col-md-5"><h6>Schedule Duration:</h6> </div>
                        <div className="col-md-7">
                          <Select className="form-control" value={schedule_duration} style={{ width: '100%' }} onChange={handleChangeDuration}>
                            <Option value="1month">1 Month</Option>
                            <Option value="2month">2 Months</Option>
                            <Option value="3month">3 Months</Option>
                            <Option value="4month">4 Months</Option>
                            <Option value="5month">5 Months</Option>
                            <Option value="6month">6 Months</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center">
                        <div className="col-md-5"><h6>Days to complete:</h6> </div>
                        <div className="col-md-7">
                          <Select className="form-control" value={completeDays} style={{ width: '100%' }} onChange={changeDays}>
                            <Option value="4d">Within 4 days</Option>
                            <Option value="7d">Within 7 days</Option>
                            <Option value="10d">Within 10 days</Option>
                          </Select>
                        </div>
                      </div>


                      <div className="col-md-12 p-1 d-flex justify-content-end align-items-center">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm float-right"
                            onClick={(e) => submitSettings(e)}
                          >
                            <i class="fa fa-check"></i> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8"> <h5>Quiz</h5></div>
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="btn btn-primary  float-right"
                      onClick={(e) => handleNewLogModal(e)}
                    >
                      <i class="ft-plus"></i> Quiz
                    </button>
                  </div>
                </div>

              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div className="table">
                    <Table
                      loading={loading}
                      dataSource={quizList}
                      columns={columns}
                      pagination={{
                        pageSize: 6,
                        showSizeChanger: true,
                        position: ["bottomCenter"],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">
                Title <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setQuizTitle(e.target.value)}
                required
              />
            </div>
            <div className="col-md-12">
              <label className="form-label">
                Description <span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                className="form-control"
                value={description}
                rows={2}
                onChange={(e) => setQuizDescription(e.target.value)}></textarea>
            </div>
            <div className="col-md-12">
              <label className="form-label">
                Total Question <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="form-control"
                min={1}
                value={total_questions}
                onChange={(e) => setQuizTotalQuestion(e.target.value)}
                required
              />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        title={questionModalTitle}
        visible={questionMVisible}
        width={900}
        onCancel={() => setQuestionMVisible(false)}
        onOk={(e) => {
          setQuestionMVisible(false)
        }}
      >
        <div>
          <>
            {isEditing ? (<>
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label">
                    Answer Type <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-control"
                    value={newQuestionType}
                    required
                    onChange={(e) => setNewQuestionType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="MCQ">
                      Single Selection
                    </option>
                    <option value="MS">
                      Multi Selection
                    </option>
                    <option value="T">
                      Text box
                    </option>
                  </select>
                </div>
                <div className="col-md-8">
                  <label className="form-label">
                    Question <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={newQuestion}
                    onChange={(e) => setNewQuestion(e.target.value)}
                    required
                  />

                </div>
                <div className="col-md-1 text-center">
                  <button type="button" class="btn btn-warning btn-icon round center mt-3" onClick={(e) => handleNewAnswerModal(e)}><i class="fa fa-plus"></i></button>
                </div>
              </div>
            </>) : (<></>)}
            {questionAndAnswser.length == 0 ? (<><h6 className="mt-3">No Questions...</h6></>) : (<></>)}
            {questionAndAnswser.map((list, i) => {
              return (<>
                <QuestionAndAnwserCard questionData={list} answersData={list.answers} usersAnswers={[]}/>
              </>);
            })}
          </>
        </div>
      </Modal>

      <Modal
        title={answerModalTitle}
        visible={answerMVisible}
        maskClosable={false}
        confirmLoading={confirmLoading}
        width={650}
        okText="Create new Q & A"
        onCancel={() => setAnswerMVisible(false)}
        onOk={(e) => {
          submitQuestionAndAnswers(e);
        }}
      >
        <div>
          <div className="row">
            <div className="col-md-12">
              <h6>Q: {newQuestion}</h6>
            </div>
            <div className="col-md-8">
              <label className="form-label">
                Answer <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={newAnswer}
                onChange={(e) => setNewAnswer(e.target.value)}
                required
              />

            </div>
            <div className="col-md-2">
              <label className="form-label">
                Is correct?
              </label>
              <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={(e) => onChangeIsCorrect(e)} checked={newAnswerIsCorrect} />
            </div>
            <div className="col-md-2">
              <button type="button" class="btn btn-sm btn-warning center mt-3" onClick={(e) => addAnswers(e)}>Add</button>
            </div>
            {newAnswersArray.length != 0 ? (<><div className="col-md-12 mt-2"><h6>Answer List:</h6> </div></>) : (<></>)}
            <div className="col-md-12 d-flex row m-1 pt-1">

              {newAnswersArray.map((answer, i) => {
                return (<>
                  <div className="col-md-6 d-flex justify-content-between">
                    <div>
                      {newQuestionType == 'MCQ' || newQuestionType == 'MS' ? (<>{answer.is_correct == 1 ? (<><Radio defaultChecked={true} checked={true}></Radio></>) : (<><Radio defaultChecked={false} checked={false} disabled={true}></Radio></>)}</>) : (<></>)}
                      {answer.answer_text}</div>
                    <div>  <button className="btn btn-sm bg-light-danger mb-1" onClick={() => {
                      setNewAnswersArray(
                        newAnswersArray.filter(a =>
                          a.id != answer.id
                        )
                      );
                    }}>
                      <i class="fa fa-trash"></i>
                    </button></div>
                  </div>
                </>)
              })}
            </div>
          </div>
        </div>

      </Modal>
    </>
  );
}
export default AlcoholTrainingSetting;
