import React, { useState, useEffect } from "react";

import { Table } from "antd";
import { getComplianceAccessSections } from "../../services/ComplianceAPIServices";
import { Link } from "react-router-dom";
import PDFViewer from "../../components/PDFViewer";

function ComplianceManagement() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name"
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
            <Link to={`/${record.web_route}?id=${record.id}#view`}>
              <button
                className="badge badge-pill bg-light-success mb-1 mr-2"
                style={{ border: '2px solid #dadada' }}
              >
                <i className="fa fa-eye"></i> view
              </button>
              </Link>
              {record.is_setting_available == 1 ? (<>
                <Link to={`/${record.web_route}?id=${record.id}#setting`}>
                  <button
                    className="badge badge-pill bg-light-info mb-1 mr-2"
                    style={{ border: '2px solid #dadada' }}
                  >
                    <i className="fa fa-cog"></i> Settings
                  </button>
                </Link>
              </>) : (<></>)}

            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);


  const loadTableData = () => {
    setLoading(true);
    getComplianceAccessSections().then((response) => {
      setTableData(response)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Compliance Management </h3>
        </div>
      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ComplianceManagement;
