import React, { useState, useEffect, useRef } from "react";
import { getLoggedUserDetails, changePasswordAPI } from "../services/AuthAPIServices";
import Swal from "sweetalert2";
import { adminUrl, displayUrl } from '../UrlPaths';
import { Modal,Button } from "antd";
import "antd/dist/antd.css";
import {
  updateCompanyDataInProfile
} from "../services/OrganizationAPIServices";

function UserProfile() {
  const inputRef = useRef();
  const user_id = localStorage.getItem("hms_user_id");
  const [role_name, setUserRoleName] = useState("");
  const [user_role, setUserRole] = useState('0');
  const [user_name, setUserName] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [registered_date, setRegisteredDate] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirmed_new_password, setConfirmedNewPassword] = useState("");
  const spinner = document.getElementById("spinner");
  const img_path = localStorage.getItem("hms_img_path");
  const [company_full_name, setCompanyName] = useState("");
  const [address, setCompanyAddress] = useState("");
  const [email, setCompanyEmail] = useState("");
  const [contact_no, setCompanyContactNo] = useState("");
  const [short_name, setCompanyShortName] = useState("");
  const [latitude, setCompanyLatitude] = useState("");
  const [longtitue, setCompanyLongtitue] = useState("");
  const [colour_theme, setCompanyColourTheme] = useState("");
  const [text_colour, setCompanyTextColour] = useState("");
  const [website, setWebsite] = useState("");
  const [logoPath, setLogoPath] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add Content");
  const [image, setImage] = useState("");
  const [existedImage, setExistedImage] = useState("");
  const [company_id, setCompanyId] = useState("0");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [attendance_type, setAttendanceType] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  function loadData(){
    const user = {
      user_id,
    };
    getLoggedUserDetails(user).then((response) => {
     
      setUserRoleName(response.data.user.role_name);
      setUserName(response.data.user.first_name);
      setUserEmail(response.data.user.email);
      setRegisteredDate(response.data.user.registered_date);
      setUserRole(response.data.user.user_role);
      setCompanyId(response.data.user.company_id);
      spinner.style.display = "none";
      if(response.data.user.user_role >= 2){
        setExistedImage(response.data.company.logo_path);
        setCompanyAddress(response.data.company.address);
        setCompanyColourTheme(response.data.company.colour_theme);
        setCompanyContactNo(response.data.company.contact_no);
        setCompanyEmail(response.data.company.email);
        setCompanyLatitude(response.data.company.latitude);
        setCompanyLongtitue(response.data.company.longtitue);
        setCompanyName(response.data.company.company_full_name);
        setCompanyShortName(response.data.company.short_name);
        setCompanyTextColour(response.data.company.text_colour);
        setLogoPath(response.data.company.logo_path);
        setWebsite(response.data.company.website);
        setAttendanceType(response.data.company.attendance_type)
      }
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      old_password == "" ||
      old_password == null ||
      new_password != confirmed_new_password ||
      new_password.length < 8
    ) {
      if (old_password == "" || old_password == null) {
        Swal.fire("warning", "Please fill the old password.", "warning");
      } else if (new_password.length < 8) {
        Swal.fire(
          "warning",
          "Password must be at least 8 characters...!",
          "warning"
        );
      } else if (new_password != confirmed_new_password) {
        Swal.fire("warning", "Confirmed password does not match..!", "warning");
      }
    } else {
      const reset_data = {
        new_password,
        old_password,
        user_id
      };
      changePasswordAPI(reset_data).then((res) => {
      
        if (res.status_code == 1) {
          Swal.fire("success", "" + res.message, "success");
          setOldPassword("");
          setNewPassword("");
          setConfirmedNewPassword("");

        } else {
          Swal.fire("warning", "" + res.message, "warning");
        }
      });
    }
  };

  const handleEditDataForm = () => {
    setModalTitle("Edit Company Main Info");
    if (image) {
      inputRef.current.value = "";
    }
    setVisible(true);
  };

  const handleSubmitMainData = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", company_id);
    data.append("company_full_name", company_full_name);
    data.append("short_name", short_name);
    data.append("address", address);
    data.append("longtitue", longtitue);
    data.append("latitude", latitude);
    data.append("contact_no", contact_no);
    data.append("email", email);
    data.append("website", website);
    data.append("text_colour", text_colour);
    data.append("colour_theme", colour_theme);
    data.append("logo", image);
    data.append("status", 1);
    data.append("attendance_type", attendance_type);

    setButtonLoading(true);
    updateCompanyDataInProfile(data).then((response) => {
        if (response.data.status_code == 1) {
            Swal.fire("Success", ""+response.data.message, "success").then(function() {
              setVisible(false);
              loadData()
            });

        } else {
            Swal.fire("Warning", " " + response.data.message, "warning");
        }
        setButtonLoading(false);
    });

};
  return (
    <>
      <div class="row">
        <div class="col-12">
          <h4 class="content-header">Settings</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 mt-3">
          <ul class="nav flex-column nav-pills" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="general-tab"
                data-toggle="tab"
                href="#general"
                role="tab"
                aria-controls="general"
                aria-selected="true"
              >
                <i class="ft-info mr-1 align-middle"></i>
                <span class="align-middle">Account Info</span>
              </a>
            </li>

            {user_role != '1' && user_role != '0' ? (<>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="company-tab"
                  data-toggle="tab"
                  href="#company-section"
                  role="tab"
                  aria-controls="company-section"
                  aria-selected="false"
                >
                  <i class="ft-home mr-1 align-middle"></i>
                  <span class="align-middle">Company Info</span>
                </a>
              </li>
            </>) : (<></>)
            }
            <li class="nav-item">
              <a
                class="nav-link"
                id="change-password-tab"
                data-toggle="tab"
                href="#change-password"
                role="tab"
                aria-controls="change-password"
                aria-selected="false"
              >
                <i class="ft-lock mr-1 align-middle"></i>
                <span class="align-middle">Change Password</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="tab-content">
                  <div
                    class="tab-pane active"
                    id="general"
                    role="tabpanel"
                    aria-labelledby="general-tab"
                  >
                    <div class="media">
                      <img
                        src={`${displayUrl}/${img_path}`}
                        alt="profile-img"
                        class="rounded mr-3"
                        height="64"
                        width="64"
                      />
                      <div class="media-body">
                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-1">
                          <h4>{user_name}</h4>
                        </div>
                        <p class="text-muted mb-0  mt-sm-0">{role_name}</p>
                      </div>
                    </div>
                    <hr class="mt-1 mt-sm-1" />
                    <form novalidate>
                      <div class="row">
                        <div class="col-12 form-group">
                          <label for="email">E-mail</label>
                          <div class="controls">
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              value={user_email}
                              placeholder="E-mail"
                              readOnly
                            />
                          </div>
                        </div>

                        <div class="col-12 form-group">
                          <label for="company">Registered Date</label>
                          <div class="controls">
                            <input
                              type="text"
                              id="company"
                              class="form-control"
                              value={registered_date}
                              placeholder="Date"
                              aria-invalid="false"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div
                    class="tab-pane"
                    id="company-section"
                    role="tabpanel"
                    aria-labelledby="company-tab"
                  >
                    <div class="media">
                      <img
                        src={`${adminUrl}${logoPath}`}
                        class="rounded mr-3"
                        height="64"
                        width="64"
                      />
                      <div class="media-body">
                        <div class="col-12 d-flex flex-sm-column flex-column justify-content-start px-0 mb-sm-1">
                          <h4>{company_full_name} 
                          {user_role == '2' ? (<>
                            <span style={{ float: 'right' }}>
                            <button
                            type="button"
                            className="btn btn-light-info  float-right"
                            onClick={(e) => handleEditDataForm(e)}
                          >
                            <i className="fa fa-edit"></i>
                          </button></span>
                          </>):(<></>)}
                         
                          </h4>
                          <span>{email}, {contact_no}</span>
                        </div>
                        <p class="text-muted mb-0  mt-sm-0">{address}</p>
                      </div>
                    </div>
                    <hr class="mt-1 mt-sm-1" />
                    <form novalidate>
                      <div class="row">
                        <div class="col-md-3 col-sm-6 form-group">
                          <label>Latitude</label>
                          <div class="controls">
                            <input
                              type="text"
                              class="form-control"
                              value={latitude}
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 form-group">
                          <label>Longitude</label>
                          <div class="controls">
                            <input
                              type="text"
                              class="form-control"
                              value={longtitue}
                              readOnly
                            />
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 form-group">
                          <label>Theme Color</label>
                          <div class="controls">
                            <span class="badge mb-1 mr-2" style={{ color: `${colour_theme}`, backgroundColor: `${colour_theme}` }}>{colour_theme}</span>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-6 form-group">
                          <label>Text Color</label>
                          <div class="controls">
                            <span class="badge mb-1 mr-2" style={{ color: `${text_colour}`, backgroundColor: `${text_colour}` }}>{text_colour}</span>
                          </div>
                        </div>
                        <div class="col-12 form-group">
                          <label>Website</label>
                          <div class="controls">
                            <a
                              href={website}
                              target="_blank"
                            >{website}</a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>


                  <div
                    class="tab-pane"
                    id="change-password"
                    role="tabpanel"
                    aria-labelledby="change-password-tab"
                  >
                    <form
                      id="pwdchange-form"
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div class="form-group">
                        <label for="old-password">Old Password</label>
                        <div class="controls">
                          <input
                            type="password"
                            id="old-password"
                            class="form-control"
                            placeholder="Old Password"
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="new-password">New Password</label>
                        <div class="controls">
                          <input
                            type="password"
                            id="new-password"
                            class="form-control"
                            placeholder="New Password"
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="retype-new-password">
                          Retype New Password
                        </label>
                        <div class="controls">
                          <input
                            type="password"
                            id="retype-new-password"
                            class="form-control"
                            placeholder="New Password"
                            value={confirmed_new_password}
                            onChange={(e) =>
                              setConfirmedNewPassword(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div class="d-flex flex-sm-row flex-column justify-content-end">
                        <button
                          type="submit"
                          class="btn btn-primary mr-sm-2 mb-1"
                        >
                          Save Changes
                        </button>
                        <button type="reset" class="btn btn-secondary mb-1">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        maskClosable={false}
        title={modalTitle}
        visible={visible}
        okText="Save"
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmitMainData(e);
        }}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
          <Button key="submit" type="primary" loading={buttonLoading} onClick={handleSubmitMainData}>
            Save
          </Button>
        
        ]}
      >
        <form
          id="form-new-employee"
          onSubmit={(e) => {
            handleSubmitMainData(e);
          }}
        >
          <div className="form-row">
            <div className="col-md-3">
              <div className="form-group position-relative">
                <img alt={'logo'} className="img-fluid" width={150}
                  src={`${adminUrl}${existedImage}`} />
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group position-relative">
                <label htmlFor="company_full_name">
                  Company Full Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="company_full_name"
                  className="form-control"
                  placeholder={"Company Full Name"}
                  name="company_full_name"
                  value={company_full_name}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="form-action-7">Address <span
                  style={{ color: "red" }}> *</span></label>
                <textarea
                  rows="4"
                  className="form-control"
                  value={address}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="short_name">
                  Short Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="short_name"
                  className="form-control"
                  placeholder="Short Name"
                  name="short_name"
                  value={short_name}
                  onChange={(e) => setCompanyShortName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="latitude">
                  latitude <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="latitude"
                  className="form-control"
                  placeholder="Latitude"
                  name="latitude"
                  value={latitude}
                  onChange={(e) => setCompanyLatitude(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label> longitude <span
                  style={{ color: "red" }}>*</span></label>
                <input
                  type="text"
                  id="longitude"
                  className="form-control"
                  placeholder="Longitude"
                  name="longitude"
                  value={longtitue}
                  onChange={(e) => setLongtitue(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group position-relative">
                <label htmlFor="email">
                  Website
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="url"
                  id="website"
                  className="form-control"
                  placeholder="Website"
                  name="website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group position-relative">
                <label htmlFor="email">
                  Email
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-group position-relative">
                <label>
                  Contact No <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="contact_no"
                  className="form-control"
                  placeholder="Contact No (Ex:+94XXXXXXXXX)"
                  name="contact_no"
                  value={contact_no}
                  onChange={(e) => setCompanyContactNo(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="form-group position-relative">
                <label>
                  Replace Logo
                </label>
                <input
                  type="file"
                  name="document"
                  accept="image/*"
                  className="form-control"
                  ref={inputRef}
                  onChange={(e) =>
                    setImage(inputRef.current.files[0])
                  }
                />
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="form-group position-relative">
                <label>
                  text colour
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="color"
                  className="form-control"
                  value={text_colour}
                  onChange={(e) => setCompanyTextColour(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="form-group position-relative">
                <label>
                  theme colour
                  <span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="color"
                  className="form-control"
                  value={colour_theme}
                  onChange={(e) => setCompanyColourTheme(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default UserProfile;
