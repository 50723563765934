import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';
import $ from "jquery";



export async function getUserRoles() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getUserRoles',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
  
    return [];
  }
}

export async function getAllPermissions() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllPermissions',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    
    return [];
  }
}

export async function rolePermissionStore(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/storeRolePermission',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
   
    return error;
  }
}

export async function getRolePermissions(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getRoleBasedPermissions',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getGrantedPermissions(data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/getGrantedPermissions',data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}


export async function checkAccess(base_data) {
  try {
    const response = await axios.post(
      APIEndpoint+'/checkRouteAccess',base_data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}