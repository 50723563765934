import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import AgeVerification from "../../components/compliance/AgeVerification";
import AgeVerificationLogView from "../../components/compliance/AgeVerificationLogView";
import BackButton from "../../components/BackButton";

function AgeManagement() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const hash = location.hash;
  const [actionText, setActionText] = useState(hash.replace('#',''));

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <BackButton/>
          <h3> Age verification Management - {actionText.charAt(0).toUpperCase() + actionText.slice(1).toLowerCase()}</h3>
        </div>
      </div>
      {actionText == 'setting' ? (<AgeVerification id={id}/>):(<AgeVerificationLogView id={id}/>)}
     
    </>
  );
}
export default AgeManagement;
