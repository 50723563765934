import React, { useState, useEffect } from "react";
import { Link,useLocation  } from "react-router-dom";
import { getEmployees } from "../../services/EmployeeAPIServices";
import { Table,Button,Input } from "antd";
import "antd/dist/antd.css";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { displayUrl } from '../../UrlPaths';

function ProfileList() {
  const location = useLocation()
  const { search } =  location.state ?? {}

  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const spinner = document.getElementById("spinner");
  const [searchedText, setSearchedText] = useState("");

  const columns = [
    {
      key: "email",
      title: "Employee",
      render: (record) => {
        return (
            <>
              <div className="d-flex flex-column justify-content-between">
               
                  <div> <Link to={`/employee-view/${record.id}`}>
                    <div>
                    <img
                      src={`${displayUrl}/${record.profile_img_path}`}
                      className="avatar mr-2"
                      alt="avatar"
                      width="30"
                      height="30"
                  />
                    <span>{record.name_with_initial} {record.is_sponsored_emp == 1 ? <span className="badge badge-pill badge-light" style={{padding:'5px'}}><small>Sponsor</small> </span>:''}</span>
                    </div>

                   </Link>
                   </div>
                 <div>
                 <span className="text-muted">{record.email}</span>
                 </div>
                 
              
              </div>
            </>
        );
      },
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Employee"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
            >
              reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      key: "team_name",
      title: "Team",
      filteredValue: [searchedText],
      sorter: (a, b) => a.team_name.length - b.team_name.length,
      render: (record) => {
        return (<>{record.team_name}</>)
      },
      onFilter: (value, record) => {
        return (
          record.name_with_initial.toLowerCase().includes(value.toLowerCase()) ||
          record.email.toLowerCase().includes(value.toLowerCase()) ||
          record.team_name.toLowerCase().includes(value.toLowerCase()) ||
          record.designation_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "designation_name",
      title: "Designation",
      dataIndex: "designation_name",
      sorter: (a, b) => a.designation_name.length - b.designation_name.length,
    },
    {
      key: "mobile_no",
      title: "Mobile no",
      dataIndex: "mobile_no",
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {record.status == "1" ? (
              <div className="flex">
                <span className="badge bg-light-success">Active</span>
              </div>
            ) : (
              <div className="flex">
                <span className="badge bg-light-danger">Inactive</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "action",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <Link to={`/employee-edit/${record.id}`}>
                <button className="badge badge-pill bg-light-danger action-btn">
                  <i class="ft-edit"></i>
                </button>
              </Link>
            </div>
          </>
        );
      },
    },
  ];
  useEffect(() => {

    if (search != "" && search != null) {
      setSearchedText(search);
    }
    setLoading(true);
    getEmployees().then((response) => {
      spinner.style.display = "none";
      setData(response);
      setLoading(false);
    });
  }, []);


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3> Existing Employees</h3>
        </div>
      </div>
      <section className="users-list-wrapper">
        <div className="users-list-table">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-content">
                  <div className="card-body">
                  <div className="row">
                  <div className="col-md-6">
                      <Input.Search
                        placeholder="Search here..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6 text-right">
                    <CSVLink
                  filename={"employess.csv"}
                  data={Data}
                  onClick={() => {
                    // console.log("clicked");
                  }}
                >
                  <i class="ft-download"></i> &nbsp;
                  All Employee CSV
                </CSVLink>
                    </div>
                    <div className="col-12">
                    <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={Data}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ProfileList;
