import { Radio } from "antd";

function QuestionAndAnwserCard({ questionData, answersData, usersAnswers=[] }) {
    return (<>
        <div className="card">
            <div className="card-content">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12"><h6>{questionData.question_no}. {questionData.question_text}   <span className="badge badge-sm badge-secondary float-right"><small>{questionData.question_type}</small></span></h6>

                        </div>
                        <div className="col-md-12 d-flex row m-1">
                            {answersData.map((answer, i) => {
                                return (<>
                                    <div className="col-md-6">
                                        {questionData.question_type == 'MCQ' || questionData.question_type == 'MS' ? (<>{answer.is_correct == 1 ? (<><Radio defaultChecked checked={true}></Radio></>) : (<><Radio defaultChecked={false} checked={false} disabled={true}></Radio></>)}</>) : (<></>)}
                                        {answer.answer_text}</div>
                                </>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default QuestionAndAnwserCard;
