import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Select, Table, Modal, Switch, Input } from "antd";
import { getCCtvLogList, getDateAndBranchBasedLogs, submitCCTVLog } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";
import { getActiveCompanyBranches } from "../../services/BranchAPIServices";

function CCTVLogView({ id }) {

  const { Option } = Select;
  const [branchesList, setBranchesList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add New CCTV Log");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [branch_id, setBranchId] = useState("");
  const [isWorkingFine, setIsWorkingFine] = useState(false);
  const [isStore31Days, setIsStore31Days] = useState(false);
  const [isSingagePlaced, setIsSingagePlaced] = useState(false);
  const [viewModalTitle, setViewModalTitle] = useState("");
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [logDataset, setLogDataset] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  const columns = [
    {
      key: "branch_name",
      title: "Branch Name",
      dataIndex: "branch_name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.branch_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      render: (value, record) => {
        return (
          <>
            {value != "" && value != null ? (<>{moment(value).format('DD/MM/YYYY')}</>) : (<></>)}
          </>
        );
      },
    },
    {
      key: "log_count",
      title: "Log Count",
      dataIndex: "log_count"
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info ml-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => handleViewModal(record)}
              >
                <i className="ft-eye"></i> View
              </button>
            </div>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    setLoading(true);
    getCCtvLogList().then((response) => {
      setTableData(response)
      setLoading(false);

    }).catch((error) => { console.error(error) });

    getActiveCompanyBranches().then((response) => {
      setBranchesList(response)
    }).catch((error) => { console.error(error) });
  }


  const handleNewLogModal = () => {
    setConfirmLoading(false);
    setBranchId("");
    setIsWorkingFine(false);
    setIsStore31Days(false);
    setIsSingagePlaced(false);
    setModalTitle("Add New CCTV Log");
    setVisible(true);
  };

  const handleSubmit = (e) => {

    var json_string = '{"0":{"question":"Is working fine?","answer":' + isWorkingFine + '},"1":{"question":"Is recording stored for 31days?","answer":' + isStore31Days + '},"2":{"question":"Is signage placed in establishment?","answer":' + isSingagePlaced + '}}';
    const data = { branch_id: branch_id, log_details: json_string };
    setConfirmLoading(true);
    submitCCTVLog(data).then((response) => {

      if (response.data.status_code == 1) {
        Swal.fire("Success", "" + response.data.message, "success").then(function () {
          setConfirmLoading(false);
          setVisible(false);
          loadTableData();
        });

      } else {
        setConfirmLoading(false);
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
    }).catch((error) => {
      setConfirmLoading(false);
      Swal.fire("Warning", "" + error.response.data.message, "warning");
    });

  }
  const onChangeLeaveTime = (e, question) => {

    question == 'fine' ? setIsWorkingFine((isWorkingFine) => !isWorkingFine) : question == 'recording' ? setIsStore31Days((isStore31Days) => !isStore31Days) : setIsSingagePlaced((isSingagePlaced) => !isSingagePlaced);
  };

  const handleViewModal = (record) => {
    setViewModalTitle(moment(record.date).format("DD/MM/YYYY") + ' - ' + record.branch_name + ' Logs');
    const dataset = { branch_id: record.branch_id, date: record.date };

    getDateAndBranchBasedLogs(dataset).then((response) => {
      setLogDataset(response.data.data)
    }).catch((error) => { console.error(error) });

    setViewModalVisible(true);
  };

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-6">
                
                      <Input.Search
                        placeholder="Search branch..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                  
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-primary  float-right"
                      onClick={(e) => handleNewLogModal(e)}
                    >
                      Add Log <i class="ft-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">
                Select Branch Name <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={branch_id}
                required
                onChange={(e) => setBranchId(e.target.value)}
              >
                <option value="" disabled>
                  Select branch
                </option>
                {branchesList.map((item, i) => {
                  return <option value={item.id}> {item.name}</option>;
                })}
              </select>
            </div>

            <div className="col-md-12">
              <label className="form-label">
                Log Details <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <div>
                <h6>Is the CCTV working fine?</h6>
              </div>
              <div>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked onChange={(e) => onChangeLeaveTime(e, "fine")} checked={isWorkingFine} />
              </div>
            </div>
            <div className="col-md-12  d-flex justify-content-between align-items-center">
              <div>
                <h6>Is the CCTV recording stored for 31 days?</h6>
              </div>
              <div>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked onChange={(e) => onChangeLeaveTime(e, "recording")} checked={isStore31Days} />
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <div>
                <h6>Is the CCTV signage placed in the estblishment?</h6>
              </div>
              <div>
                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked onChange={(e) => onChangeLeaveTime(e, "place")} checked={isSingagePlaced} />
              </div>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        title={viewModalTitle}
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        onOk={(e) => {
          setViewModalVisible(false)
        }}
      >
        {logDataset.map((list, i) => {
          let logDetails = JSON.parse(list.log_details);
          let result = Object.values(logDetails);
          
          return (<> <div className="card">
            <div className="card-content">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 mb-2"><i><span>Entered By: {list.name_with_initial}</span></i><span className="text-muted float-right">{moment(list.created_at).format('DD/MM/YYYY HH:mm')}</span></div>
                  <div className="col-md-12">
                  {result.map((details, i) => {
                  return (<>
                  
                   <h6> {details.question} <span className="float-right">{details.answer ? 'Yes' : 'No'}</span></h6>
                   
                  </>)
                  })}
                </div>
                </div>
              </div>
            </div>
          </div></>);
        })}

      </Modal>
    </>
  );
}
export default CCTVLogView;
