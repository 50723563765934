import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import {Table, Modal, Radio, Input} from "antd";
import { getAttemptedQuestionAndAnswers, getEmployeeQuizAttemptaList, getPublishedQuizList, publishQuizAttemptFinalResults, saveTextAnswerResults } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";
import { QUIZ_FAILED, QUIZ_PASS } from "../../AppConstant";
import $ from 'jquery';

function AlcoholTrainingView({ id }) {

  const [quizAttemptList, setQuizAttemptList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionModalTitle, setQuestionModalTitle] = useState("");
  const [questionMVisible, setQuestionMVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [questionAndAnswser, setQuestionAndAnswser] = useState([]);
  const [selectedAttemptId, setSelectedAttemptId] = useState("");
  const [resultDecision, setResultDecision] = useState("");
  const [score, setScore] = useState("");
  const [comment, setComment] = useState("");
  const [selectedQuizId, setSelectedQuizId] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [selectedAttemptStatus, setSelectedAttemptStatus] = useState("");
  const [quizList, setQuizList] = useState([]);
  const [filterQuizId, setFilterQuizId] = useState("all");

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.name.toLowerCase().includes(value.toLowerCase())||
          record.quiz_name.toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (value, record) => {
        return (
          <>
            {record.main_row == 1 ? (<>
              <b><span>{value}</span> </b>
            </>) : (<>
            </>)}
          </>
        );
      },
    },
    {
      key: "quiz_name",
      title: "Quiz name",
      dataIndex: "quiz_name",
      render: (value, record) => {
        return (
          <>
            {record.main_row == 1 ? (<>
              <b><span>{value}</span> </b>
            </>) : (<>
              Attempt : {record.attempt_no}
            </>)}

          </>
        );
      },
    },
    {
      key: "last_attempted_date",
      title: "Attempted Date",
      dataIndex: "last_attempted_date",
      render: (value, record) => {
        return (
          <>
            {value != "" && value != null ? (<>{moment(value).format('DD/MM/YYYY')}</>) : (<></>)}
          </>
        );
      },
    },
    {
      key: "latest_marks",
      title: "Latest Marks",
      dataIndex: "latest_marks"
    },
    {
      key: "latest_status",
      title: "Status",
      dataIndex: "latest_status"
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              {record.main_row == 1 ? (<>

              </>) : (<>
                <button
                  className="badge badge-pill bg-light-info ml-2"
                  style={{ border: '2px solid #dadada' }}
                  onClick={() => handleQuestionModal(record, 'view')}
                >
                  <i className="ft-eye"></i> View
                </button>
              </>)}


            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadQuizAttemptTableData();
  }, [filterQuizId]);

  useEffect(() => {
  }, [quizAttemptList]);

  const loadQuizAttemptTableData = () => {
    setLoading(true);

    getEmployeeQuizAttemptaList(filterQuizId).then((response) => {
      setQuizAttemptList(response);
      setLoading(false);

    }).catch((error) => { console.error(error) });

    getPublishedQuizList().then((response) => {
      setQuizList(response)
    }).catch((error) => { console.error(error) });
  }

  const loadQuizQAndAData = (quizID, attemptID) => {
    const dataset_json = { quiz_id: quizID, attempt_id: attemptID }
    getAttemptedQuestionAndAnswers(dataset_json).then((response) => {
      setSelectedUsername(response.data.data.user)
      // setQuestionModalTitle(prevText => prevText + " - " + response.data.data.user)
      setQuestionAndAnswser(response.data.data.q_and_a);
    }).catch((error) => { console.error(error) });
  }

  const submitQuizAttemptFinalResults = () => {
    if(resultDecision == ""){
      Swal.fire("Warning", "Please select a result decision.", "warning");
    }else{
      const data_json = { quiz_id: selectedQuizId, attempt_id: selectedAttemptId, score: score, status: resultDecision, comments: comment }
   
      publishQuizAttemptFinalResults(data_json).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success");
          setIsEditing(false)
          loadMarksSection(resultDecision, score, comment)
          loadQuizAttemptTableData();
          loadQuizQAndAData(selectedQuizId, selectedAttemptId);
        } else {
          setResultDecision("");
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
  
  
      }).catch((error) => { console.error(error) });
    }

  }


  const handleQuestionModal = (data, action) => {
    setSelectedAttemptId(data.key);
    setQuestionModalTitle(data.quiz_title + ' :- (Attempt : ' + data.attempt_no + ')');
    setSelectedAttemptStatus(data.latest_status);
    setSelectedQuizId(data.quiz_id);
    loadMarksSection(data.latest_status, data.latest_marks, data.comments);
    loadQuizQAndAData(data.quiz_id, data.key)
    setQuestionMVisible(true);

  };

  const loadMarksSection = (status, marks, comments) => {
    setComment(comments == null ? "" : comments);
    setScore(marks == "" || marks == null ? "" : marks);
    setResultDecision(status == QUIZ_PASS || status == QUIZ_FAILED ? status : "");
    status == QUIZ_PASS || status == QUIZ_FAILED ? setIsEditing(false) : setIsEditing(true);
  }

  const submitTextAnswerDecision = (qusId) => {
    let addProcess = false;

    let selected_value = $("input[name='decisionSelection" + qusId + "']:checked").val();
    selected_value != "" || selected_value != undefined ? addProcess = true : addProcess = false;

    if (addProcess) {
      const submit_data = { attempt_id: selectedAttemptId, question_id: qusId, decision: selected_value }
      saveTextAnswerResults(submit_data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success");
          
          loadQuizQAndAData(selectedQuizId, selectedAttemptId);
          loadMarksSection(resultDecision, response.data.data.marks, comment)
          loadQuizAttemptTableData();
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
       

      }).catch((error) => { console.error(error) });
    } else {
      Swal.fire("Warning", "Please select decision", "warning");
    }

  }


  const onChangeQuiz = (e) => {
    const value = e.target.value;
    setFilterQuizId(value);
    loadQuizAttemptTableData();
  };
  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-12"> <h5>Employee Quiz Attempts Evaluation</h5></div>
                  <div className="col-md-4">

                  <select
                    className="form-control"
                    value={filterQuizId}
                    required
                    onChange={onChangeQuiz}
                  >
                    <option value="all">
                      All Quiz
                    </option>
                    {quizList.map((item, i) => {
                      return <option value={item.id}> {item.title}</option>;
                    })}
                  </select>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-6">
                  <Input.Search
                        placeholder="Search employee name or quiz name..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                  </div>
                  
                </div>

              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div className="table">
                    <Table
                      loading={loading}
                      dataSource={quizAttemptList}
                      columns={columns}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        position: ["bottomCenter"],
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={`${questionModalTitle} - ${selectedUsername} - [${selectedAttemptStatus}]`} 
        visible={questionMVisible}
        width={900}
        onCancel={() => setQuestionMVisible(false)}
        onOk={(e) => {
          setQuestionMVisible(false)
        }}
      >
        <div>
          <>
            <div className="row">
              <div className="col-md-3">
                <label className="form-label">
                  Result Decision <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  value={resultDecision}
                  disabled={!isEditing}
                  required
                  onChange={(e) => setResultDecision(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Pass">
                    Pass
                  </option>
                  <option value="Retake">
                    Retake
                  </option>
                </select>
              </div>
              <div className="col-md-2">
                <label className="form-label">
                  Score <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={score}
                  readOnly={!isEditing}
                  onChange={(e) => setScore(e.target.value)}
                  required
                />

              </div>
              <div className="col-md-5">
                <label className="form-label">
                  Comment
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={comment}
                  readOnly={!isEditing}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />

              </div>
              {isEditing ? (<>
                <div className="col-md-2 text-center">
                  <button type="button" class="btn btn-sm btn-warning center mt-3" onClick={(e) => submitQuizAttemptFinalResults(e)}>Publish Result</button>
                </div>
              </>) : (<></>)}
            </div>

            {questionAndAnswser.length == 0 ? (<><h6 className="mt-3">No Questions...</h6></>) : (<></>)}
            {questionAndAnswser.map((list, i) => {
              let correctAnswers = [];
              return (<>
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h6>{list.question.question_no}. {list.question.question_text}   {list.user_answers.is_correct == 1 ? (<><span className="badge badge-sm badge-pill bg-light-success float-right"><small>Correct</small></span></>) :
                            (<>{list.user_answers.is_correct == 0 ?
                              (<><span className="badge badge-sm badge-pill bg-light-danger float-right"><small>Wrong</small></span></>) :
                              (<>{list.user_answers.length == 0 || (list.user_answers.answer_type == 'T' && (list.user_answers.answer_text == '' || list.user_answers.answer_text == null)) ?
                                (<><span className="badge badge-sm badge-pill bg-light-danger float-right"><small>Not Answered</small></span></>) :
                                (<><span className="badge badge-sm badge-pill bg-light-info float-right"><small>Decision needed</small></span></>)}</>)}</>)}</h6>
                        </div>
                        <div className="col-md-12 d-flex row m-1">
                          {list.question.answers.map((answer, i) => {
                            let answersIdArray = [];
                            if (answer.is_correct == 1) {
                              correctAnswers.push(answer.answer_text)
                            }
                         
                            if(list.user_answers.selected_answers != undefined && (list.question.question_type == 'MCQ' || list.question.question_type == 'MS')){
                              
                              list.user_answers.selected_answers.forEach( ele => answersIdArray.push(+ele))
                            }
                            return (<>
                              {list.question.question_type == 'MCQ' || list.question.question_type == 'MS' ? (<>
                                <div className="col-md-6">
                                  {answersIdArray?.includes(answer.id) ? (<><Radio defaultChecked checked={true}></Radio>  {answer.answer_text} </>) : (<><Radio defaultChecked={false} checked={false} disabled={true}></Radio>  {answer.answer_text}</>)}
                                </div>
                              </>) : (<></>)}
                            </>)
                          })}
                        </div>
                        {list.question.question_type == 'T' ? (<>
                          <div className="col-md-10">
                            <b><span>User entered answer: </span></b>
                            <span> {list.user_answers.question_id == list.question.id && (list.user_answers.answer_text == "" || list.user_answers.answer_text == null) ? (<>Empty Answer</>) : (<>{list.user_answers.answer_text}</>)}</span>
                          </div>
                          <div className="col-md-2 d-flex">
                            {list.user_answers.length == 0 || (list.user_answers.question_id == list.question.id && (list.user_answers.is_correct == 0 || list.user_answers.is_correct == 1 )) ?
                              (<></>) : (<>
                                <div>
                                  <input type="radio" name={`decisionSelection${list.question.id}`} value="1" />
                                  <label>&nbsp;Correct</label><br />
                                  <input type="radio" name={`decisionSelection${list.question.id}`} value="0" />
                                  <label>&nbsp;Wrong </label>
                                </div>
                                <button className="btn btn-sm bg-light-danger m-1 mb-1" onClick={() => submitTextAnswerDecision(list.question.id)}>
                                  <i class="fa fa-check"></i>
                                </button>
                              </>)}

                          </div>
                        </>) : (<></>)}
                        {list.question.question_type != 'T' ? (<>
                          <div className="col-md-12">
                            <h6>Correct Answers: </h6>
                          </div>
                          <div className="col-md-12 d-flex row m-1">
                            {correctAnswers.map((correctAnswers, i) => {
                              return (<>
                                <span class="badge badge-light mb-1 mr-2">{correctAnswers}</span>
                              </>)
                            })}
                          </div>
                        </>) : (<></>)}

                      </div>
                    </div>
                  </div>
                </div>
              </>);
            })}
          </>
        </div>
      </Modal>
    </>
  );
}
export default AlcoholTrainingView;
