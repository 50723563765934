import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';


export async function getNotifications() {
  try {
    const response = await axios.get(
      APIEndpoint+'/notifications-admin',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    
    return [];
  }
}

