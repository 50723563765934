import React, { useState, useEffect, useRef } from "react";
import { Input, Table, Button } from "antd";
import "antd/dist/antd.css";
import {
  getAllSalarySlips,
  uploadSlips,
  mailSendingAPI,
} from "../services/PayrollAPIServices";
import { SearchOutlined } from "@ant-design/icons";
import { paySilpUrl } from "../UrlPaths";

import Swal from "sweetalert2";

function Payroll() {
  const inputRef = useRef();

  var current_year_month = "";
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const date = new Date().getDate();

  if (month < 10) {
    current_year_month = year + "-0" + month;
  } else {
    current_year_month = year + "-" + month;
  }

  const spinner = document.getElementById("spinner");

  const [sending_month, setMonth] = useState(current_year_month);
  const [search_month, setSearchMonth] = useState("");
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedPayslip, setSelectedPayslip] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  const columns = [
    {
      key: "first_name",
      title: "Name",
      filteredValue: [searchedText],
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      render: (record) => {
        return (
          <>
            {record.first_name} {record.last_name}
          </>
        );
      },
      onFilter: (value, record) => {
        return (
          record.first_name.toLowerCase().includes(value.toLowerCase()) ||
          record.last_name.toLowerCase().includes(value.toLowerCase()) ||
          record.employment_type.toLowerCase().includes(value.toLowerCase()) ||
          record.slip_month.toLowerCase().includes(value.toLowerCase())
        );
      },
      width: "25%",
    },
    {
      key: "year",
      title: "Month",
      dataIndex: "slip_month",
      sorter: (a, b) => a.slip_month.length - b.slip_month.length,
    },
    {
      key: "employment_type",
      title: "Employee Type",
      dataIndex: "employment_type",
      sorter: (a, b) => a.employment_type.length - b.employment_type.length,
    },
    {
      key: "view",
      title: "View",
      render: (record) => {
        return (
          <>
            {record.path !== null && record.path !== "" ? (
              <div className="flex">
                <button
                  className="badge badge-pill bg-light-info action-btn"
                  onClick={() => popupDocument(`${paySilpUrl}/${record.path}`)}
                >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </div>
            ) : (
              <div className="flex"></div>
            )}
          </>
        );
      },
    },
    {
      key: "upload",
      title: "Upload",
      render: (record) => {
        return (
          <>
            {record.path !== null && record.path !== "" ? (
              <div className="flex">
                <span className="badge bg-light-success">Uploaded</span>
              </div>
            ) : (
            <div>
                <form
                  id={`form-document${record.id}`}
                  encType="multipart/form-data"
                  onSubmit={uploadSlip(record)}
                >
                    <div className="d-flex">
               
                  <input
                      type="file"
                      name="payslip"
                      id={`payslip${record.id}`}
                      accept="application/pdf"
                      required
                      ref={inputRef}
                      onChange={(e) => setSelectedPayslip(e.target.files[0])}
                      style={{width:'80%'}}
                    />
                
                     <button
                      className="badge bg-light-danger action-btn"
                      // style={{ color: "black" }}
                      type="submit"
                    >
                      <i class="fa fa-upload" aria-hidden="true"></i>
                       
                    </button>
                  
                   </div>
                </form>
                </div>
            )}
          </>
        );
      },
    },
    {
      key: "emailed",
      title: "Emailed",
      render: (record) => {
        return (
          <>
            <div className="flex">
              <b>
                {record.emailed} {record.sent_at}
              </b>
            </div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    let requested_data = {
      search_month,
    };
    setLoading(true);
    getAllSalarySlips(requested_data).then((response) => {
      spinner.style.display = "none";
      setData(response);
      setLoading(false);
    });
  }, []);

  const uploadSlip = (record) => (e) => {
    e.preventDefault();
    setSelectedRow(record.id);

    const data = new FormData();

    data.append("payslip", selectedPayslip);
    data.append("payslip_row_id", record.id);

    uploadSlips(data).then((response) => {
      let requested_data = {
        search_month,
      };
      if (response.status_code == "1") {
        setLoading(true);
        getAllSalarySlips(requested_data).then((response) => {
          setData(response);
          setLoading(false);
        });
        Swal.fire({
          title: "Success",
          text: "Successfully updated",
          icon: "success",
          timer: 1500,
        });
      } else {
        Swal.fire("warning", " " + response.data.message, "warning");
      }
    });
  };

  function mailSending(event) {
    const monthData = {
      sending_month,
    };
    setButtonLoading(true);

    mailSendingAPI(monthData).then((response) => {
      if (response.status_code == "1") {
        Swal.fire({
          title: "Success",
          text: "" + response.message,
          icon: "success",
          timer: 2000,
        });
      } else {
        Swal.fire("warning", " " + response.message, "warning");
      }
      setLoading(true);

      const requested_data = {
        search_month,
      };
      getAllSalarySlips(requested_data).then((response) => {
        setData(response);
        setLoading(false);
      });
      setButtonLoading(false);
    });
  }

  const popupDocument = (url) => {
    let win = window.open(
      url,
      null,
      "popup,width=500,height=600,left=0,right=0"
    );
  };

  function monthFinding(event) {
    let requested_data = {
      search_month,
    };
    setLoading(true);

    getAllSalarySlips(requested_data).then((response) => {
      setData(response);
      setLoading(false);
    });
  }

  if (spinner) {
    setTimeout(() => {}, 2000);
    //
  }

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Payroll - Payslip Upload</h3>
        </div>
      </div>
      <div className="users-list-filter px-2 align-center">
        <form>
          <div className="row border rounded py-2 mb-2 mx-n2">
            <div className="col-12 col-sm-6 col-lg-3">
              <label for="users-list-role">Month</label>
              <fieldset className="form-group">
                <input
                  type="month"
                  className="form-control pickadate"
                  value={search_month}
                  onChange={(e) => setSearchMonth(e.target.value)}
                />
              </fieldset>
            </div>
            <div className="col-12 col-sm-6 col-lg-2">
              <label style={{ color: "white" }}>Find</label>
              <button
                type="button"
                className="btn btn-warning btn-block users-list-clear glow mb-0"
                onClick={monthFinding}
              >
                <i class="ft-filter m-0 mr-sm-1"></i>
                <span>Filter</span>
              </button>
            </div>
            <div className="col-12 col-sm-6 col-lg-2"></div>
            <div className="col-12 col-sm-6 col-lg-3">
              <label for="users-list-role">Month</label>
              <fieldset className="form-group">
                <input
                  type="month"
                  className="form-control pickadate"
                  value={sending_month}
                  onChange={(e) => setMonth(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="col-12 col-sm-6 col-lg-2 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-primary btn-block users-list-clear glow mb-0"
                onClick={mailSending}
                disabled={buttonLoading}
              >
                {buttonLoading && (
                  <>
                    <i className="ft-refresh-ccw"> </i>
                    <span>Processing...</span>
                  </>
                )}
                {!buttonLoading && (
                  <>
                    {" "}
                    <i class="ft-send m-0 mr-sm-1"></i>
                    <span> Mail Send</span>{" "}
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-content">
              <div className="card-body">
              <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                      <Input.Search
                        placeholder="Search here..."
                        style={{ marginBottom: 5 }}
                        value={searchedText}
                        onSearch={(value) => {
                          setSearchedText(value);
                        }}
                        onChange={(e) => {
                          setSearchedText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={Data}
                        columns={columns}
                        pagination={{
                          pageSize: 20,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                    </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Payroll;
