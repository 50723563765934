import React, { useState, useEffect } from "react";

import { Table, DatePicker, Modal } from "antd";
import { getHealthAndSafetyReports } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";
import { getActiveCompanyBranches } from "../../services/BranchAPIServices";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import BackButton from "../../components/BackButton";

function HealthAndSafetyIncidentView() {

  const { RangePicker } = DatePicker;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [branchesList, setBranchesList] = useState([]);
  const [branch_id, setBranchId] = useState("");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [viewModalTitle, setViewModalTitle] = useState("");
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [branch_name, setBranchName] = useState("");
  const [report_emp, setReportEmp] = useState("");
  const [report_date, setRepotDate] = useState("");
  const [incidentTitle, setIncidentTitle] = useState("");
  const [incidentDate, setIncidentDate] = useState("");
  const [incidentTime, setIncidentTime] = useState("");
  const [incidentLocationType, setIncidentLocationType] = useState("");
  const [incidentLocation, setIncidentLocation] = useState("");
  const [incidentDetails, setIncidentDetails] = useState("");
  const [incidentCategory, setIncidentCategory] = useState("");
  const [witness, setWitness] = useState("");
  const [involvedPeople, setInvolvedPeople] = useState("");
  const [isNearMiss, setIsNearMiss] = useState("");
  const [injuryDetails, setInjuryDetails] = useState("");
  const [treatmentDetails, setTreatmentDetails] = useState("");
  const [isPersonWorked, setIsPersonWorked] = useState("");
  const [absenceDuration, setAbsenceDuration] = useState("");
  const [isAccept, setIsAccept] = useState("");

  const columns = [
    {
      key: "branch_name",
      title: "Branch Name",
      dataIndex: "branch_name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.branch_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "entered_by",
      title: "Entered By",
      dataIndex: "entered_by",
    },
    {
      key: "created_at",
      title: "Reported At",
      dataIndex: "created_at",
      render: (value, record) => {
        return (
          <>
            {value != "" && value != null ? (<>{moment(value).format('DD/MM/YYYY HH:mm')}</>) : (<></>)}
          </>
        );
      },
    },
    {
      key: "incident_title",
      title: "Incident Title",
      dataIndex: "incident_title"
    },
    {
      key: "location_type",
      title: "Location Type",
      dataIndex: "location_type"
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info ml-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => handleViewModal(record)}
              >
                <i className="ft-eye"></i> View
              </button>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    setLoading(true);
    getHealthAndSafetyReports(branch_id, filterFrom, filterTo).then((response) => {
      setTableData(response)
      setLoading(false);
    }).catch((error) => { console.error(error) });

    getActiveCompanyBranches().then((response) => {
      setBranchesList(response)
    }).catch((error) => { console.error(error) });
  }

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setFilterTo(dateStrings[1]);
      setFilterFrom(dateStrings[0]);
    } else {
      setFilterTo("");
      setFilterFrom("");
    }
  };

  function changeDataLoad() {
    loadTableData()
  }

  const handleViewModal = (record) => {
    setViewModalTitle('Health & safety Incident Report');
    setBranchName(record.branch_name);
    setReportEmp(record.entered_by);
    setRepotDate(moment(record.created_at).format('DD/MM/YYYY'));
    setIncidentTitle(record.incident_title);
    setIncidentDate(moment(record.incident_date).format('DD/MM/YYYY'));
    setIncidentTime(record.incident_time);
    setIncidentCategory(record.incident_category);
    setIncidentLocationType(record.location_type);
    setIncidentLocation(record.location_type == 'office'? record.location_text : record.location);
    setIncidentDetails(record.incident_details);
    setWitness(record.witness);
    setIsNearMiss(record.is_near_miss == 1?'Yes':'No');
    setInvolvedPeople(record.involved_people);
    setInjuryDetails(record.injury_details);
    setTreatmentDetails(record.treatment_details);
    setIsPersonWorked(record.is_person_worked == 1? 'Yes':'No');
    setAbsenceDuration(record.absence_duration);
    setIsAccept(record.is_accept == 1 ? 'Yes' :'No');

    setViewModalVisible(true);
  };

  const options = {
    method: 'save',
    resolution: Resolution.MEDIUM,
    page: {
      margin: Margin.LARGE,
      format: 'A4',
      orientation: 'portrait',
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/jpeg',
      qualityRatio: 1
    },
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true
      }
    },
  };

  const getTargetElement = () => document.getElementById('content-id');
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <BackButton/>
          <h3> Health & Safety Incident Report - View </h3>
        </div>
      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-12"><h5>Report List</h5> </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">
                        Branch
                      </label>
                      <select
                        className="form-control"
                        value={branch_id}
                        required
                        onChange={(e) => setBranchId(e.target.value)}
                      >
                        <option value="">
                          All branches
                        </option>
                        {branchesList.map((item, i) => {
                          return <option value={item.id}> {item.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="col-md-4">

                      <label for="users-list-role">Reported Date Period</label>
                      <RangePicker
                        className="form-control"
                        ranges={{
                          Today: [moment(), moment()],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                        }}
                        onChange={onChange}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="btn btn-primary mt-3"
                        onClick={changeDataLoad}
                      >
                        <i className="ft-filter"></i> filter
                      </button>
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="table-responsive">
                        <div className="table">
                          <Table
                            loading={loading}
                            dataSource={tableData}
                            columns={columns}
                            pagination={{
                              pageSize: 10,
                              // showSizeChanger: true,
                              showQuickJumper: true,
                              position: ["bottomCenter"],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        // title={viewModalTitle}
        width={660}
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        onOk={(e) => {
          setViewModalVisible(false)
        }}
      >
        <div>
          <button className="btn btn-sm btn-primary"  onClick={() => generatePDF(getTargetElement, options)}>Generate PDF</button>
          <div  id="content-id"  style={{ width: '100%' }}>
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-2 text-center"><h5>{viewModalTitle}</h5></div>
                    <div className="col-md-3 ">Branch </div> <div className="col-md-9 col-sm-7"> : {branch_name} </div>
                    <div className="col-md-3 col-sm-5">Employee </div><div className="col-md-9 col-sm-7">: {report_emp} </div>
                    <div className="col-md-3 mb-2 col-sm-5">Report At </div><div className="col-md-9 col-sm-7">: {report_date} </div>
                    <div className="col-md-12 mb-2 text-center"><h6>{incidentTitle} </h6></div>
                    <div className="col-md-4">Incident At </div> <div className="col-md-8">: {incidentDate}, &nbsp;{incidentTime}</div>
                    <div className="col-md-4">Incident Location </div><div className="col-md-8"> : {incidentLocationType} <br/> &nbsp; {incidentLocation}</div>
                    <div className="col-md-4 mt-1">Details </div>
                    <div className="col-md-8 mt-1 text-justify"> : {incidentDetails}</div>
                    <div className="col-md-4 mt-1">Category </div>
                    <div className="col-md-8 mt-1">: {incidentCategory} </div>
                    <div className="col-md-4">Witnesses </div>
                    <div className="col-md-8 text-justify">: {witness} </div>
                    <div className="col-md-12 mb-2 mt-2"><b>Section 2 </b></div>
                    <div className="col-md-4">Involved People</div>
                    <div className="col-md-8">: {involvedPeople} </div>
                    <div className="col-md-4">Is it near miss? </div>
                    <div className="col-md-8">: {isNearMiss} </div>
                    {isNearMiss == 'No' ? (<>
                      <div className="col-md-4">Type of inajury</div>
                    <div className="col-md-8">: {injuryDetails} </div>
                    <div className="col-md-6">Provided treatments</div>
                    <div className="col-md-6">: {treatmentDetails} </div>
                    <div className="col-md-6">Is the person back to work?</div>
                    <div className="col-md-6">: {isPersonWorked} </div>
                    <div className="col-md-6">Duration of absence</div>
                    <div className="col-md-6">: {absenceDuration} </div>
                    </>):(<></>)}
                    <div className="col-md-10 mt-3">Is happy to be represented in the Trade Union?</div>
                    <div className="col-md-2 mt-3"> {isAccept} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Modal>
    </>
  );
}
export default HealthAndSafetyIncidentView;
