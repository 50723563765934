import { useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { loginAPI } from "../services/AuthAPIServices";
import Swal from "sweetalert2";
import { FINANCE_USER_ROLE, GLOBAL_ADMIN_ROLE } from "../AppConstant";

// const navigate = useNavigate();
function Login() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const spinner = document.getElementById("spinner");
  spinner.style.display = "none";
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(email == "" || email == null || password == "" || password == null){
      Swal.fire("warning", "Please fill the requried data.", "warning");
    }else{
    const credentials = {
      email,
      password,
    };
    loginAPI(credentials).then((res) => {
      // console.log(res);
      if (res.status_code == 1) {
        localStorage.setItem("hms_user", res.data.name);
        localStorage.setItem("hms_user_role", res.data.user_role);
        localStorage.setItem("hms_user_id", res.data.user_id);
        localStorage.setItem("hms_img_path", res.data.path);
        localStorage.setItem("hms_company_logo", res.data.company_image);
        localStorage.setItem("hms_authenticated", true);
        // localStorage.setItem("hms_user_rights", JSON.stringify(res.data.user_rights));
        localStorage.setItem("hms_access_token", res.data.access_token);

    //   Swal.fire("success", "Successfully login", "success");

 
    if(res.data.user_role == GLOBAL_ADMIN_ROLE){
      navigate("/organizations");
      navigate(0)
    }else if(res.data.user_role == FINANCE_USER_ROLE){
      navigate("/attendance");
      navigate(0)
    }else{
      navigate("/dashboard");
      navigate(0)
    }
      

      } else {
        Swal.fire("warning", "" + res.message, "warning");
      }
    });
  }
  };

  return (
    <>
      <div className="content-wrapper" style={{background:"#dadee3"}} >
        <section id="login" className="auth-height" >
          <div className="row full-height-vh m-0">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="card overflow-hidden">
                <div className="card-content">
                  <div className="card-body auth-img"  >
                    <div className="row m-0">
                      <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                        <img
                          src="app-assets/img/logo.jpg"
                          alt=""
                          className="img-fluid"
                          width="300"
                          height="230"
                        />
                      </div>
                      <div className="col-lg-6 col-12 px-4 py-3">
                        <h4 className="mb-2 card-title">Login</h4>
                        <p style={{color:"#79829A"}}>Welcome back, please login to your account.</p>
                        <form
                          id="login-form"
                          onSubmit={(e) => {
                            handleSubmit(e);
                          }}
                        >
                          <input
                            type="email"
                            className="form-control mb-3"
                            placeholder="Email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <input
                            type="password"
                            className="form-control mb-2"
                            placeholder="Password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <div className="d-sm-flex justify-content-between mb-3 font-small-2">
                            <div className="remember-me mb-2 mb-sm-0">
                              <div className="checkbox auth-checkbox">
                                <input
                                  type="checkbox"
                                  id="auth-ligin"
                                  checked={rememberMe}
                                  onChange={handleSetRememberMe}
                                  onClick={handleSetRememberMe}
                                />
                                <label for="auth-ligin">
                                  <span style={{color:"#79829A"}}>Remember Me</span>
                                </label>
                              </div>
                            </div>
                            <Link to={"/forgot-password"}>Forgot Password?</Link>
                          </div>
                          <div className="d-flex justify-content-between flex-sm-row flex-column">
                            <button type="submit" className="btn btn-primary" >
                              Login
                            </button>
                          </div>
                        </form>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
