import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Select, TimePicker } from "antd";
import { getNotifySetting, submitSettingDetails } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";

function AgeVerification({ id }) {

  const { Option } = Select;
  const [notifyFrom, setNotifyFrom] = useState("");
  const [notifyTo, setNotifyTo] = useState("");
  const [intervals, setIntervals] = useState("");

  const timeFormat = 'HH:mm';


  useEffect(() => {
    loadSettingsData();
  }, []);


  const loadSettingsData = () => {
    getNotifySetting(id).then((response) => {
      let notifyData = JSON.parse(response.data.data.notify_settings);
  

      let result = Object.entries(notifyData).map(([k, v]) => ({ [k]: v }));
      result.forEach((item) => {
        var key = Object.keys(item)[0];
        setIntervals(item[key].intervals);
        var timeArray = [];
        timeArray.push(moment(item[key].from, "HH:mm").format('HH:mm'), moment(item[key].to, "HH:mm").format('HH:mm'));
        timeOnChange(item[key].from, timeArray)

      });
    }).catch((error) => { console.error(error) });
  }


  const timeOnChange = (dates, dateStrings) => {

    if (dates) {
      setNotifyFrom(dateStrings[0]);
      setNotifyTo(dateStrings[1]);
    } else {
      setNotifyFrom("");
      setNotifyTo("");
    }
  };

  function handleChange(value) {
    setIntervals(value);
  }

  const submitSettings = (e) => {

    if (notifyTo == "" || notifyFrom == "") {
      Swal.fire("Warning", "Please select time period.", "warning");
    } else if (intervals == "") {
      Swal.fire("Warning", "Please select notify time interval.", "warning");
    } else {

      var json_string = '{"0":{"intervals":"' + intervals + '","from":"' + notifyFrom + '","to":"' + notifyTo + '"}}';

      const data_json = { compliance_type: id, notify_settings: json_string }
      submitSettingDetails(data_json).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadSettingsData();
          });
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });

    }

  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">

                    <div className="col-md-6">

                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" >
                        <div className="col-md-5"><h6>Notification:</h6> </div>
                        <div className="col-md-7">
                          <small className="text-muted">{notifyFrom} - {notifyTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} onChange={(e, x) => timeOnChange(e, x)} /></div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center">
                        <div className="col-md-5"><h6>Intervals:</h6> </div>
                        <div className="col-md-7">
                          <Select className="form-control" value={intervals} style={{ width: '100%' }} onChange={handleChange}>
                            <Option value="1h">1 hour</Option>
                            <Option value="2h">2 hours</Option>
                            <Option value="3h">3 hours</Option>
                            <Option value="4h">4 hours</Option>
                            <Option value="5h">5 hours</Option>
                          </Select>
                        </div>
                      </div>


                      <div className="col-md-12 p-1 d-flex justify-content-end align-items-center">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm float-right"
                            onClick={(e) => submitSettings(e)}
                          >
                            <i class="fa fa-check"></i> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AgeVerification;
