import React, { useState } from "react";
import { useParams ,useNavigate} from "react-router-dom";
import { resetPasswordAPI } from "../services/ForgotPasswordAPIServices";
import Swal from "sweetalert2";

function ResetPassword() {
  const { token, mail } = useParams();


  let resetNavigate = useNavigate();
  const [email, setEmail] = useState(mail);
  const [password, setPassword] = useState("");
  const [password_confirmed, setPasswordConfirmed] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if(email == "" || email == null || password == "" || password == null || password != password_confirmed || password.length < 8){
      if(email == "" || email == null || password == "" || password == null){
        Swal.fire("warning", "Please fill the requried data.", "warning");
      }else if(password.length < 8){
        Swal.fire("warning", "Password must be at least 8 characters...!", "warning");
       
      }else if(password != password_confirmed){
        Swal.fire("warning", "Passwords do not match..!", "warning");
  
      }
      
    }else{
    const reset_data = {
      token,
      email,
      password,
    };
    resetPasswordAPI(reset_data).then((res) => {
    
      if (res.status_code == 1) {

       Swal.fire("success", ""+res.message, "success");

 
       resetNavigate("/");
       resetNavigate(0)

      } else {
        Swal.fire("warning", "" + res.message, "warning");
      }
    });
  }
  };
  return (
    <>
      <div class="wrapper" style={{background:"#dadee3"}}>
        <div class="main-panel">
          <div class="main-content">
            <div class="content-overlay"></div>
            <div class="content-wrapper">
              <section id="regestration" class="auth-height">
                <div class="row full-height-vh m-0">
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="card overflow-hidden">
                      <div class="card-content">
                        <div class="card-body auth-img">
                          <div class="row m-0">
                            <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                              <img
                                src="../../../app-assets/img/gallery/register.png"
                                alt=""
                                class="img-fluid"
                                width="350"
                                height="230"
                              />
                            </div>
                            <div class="col-lg-6 col-md-12 px-4 py-3">
                            <form
                          id="login-form"
                          onSubmit={(e) => {
                            handleSubmit(e);
                          }}
                        >
                              <h4 class="card-title mb-2">Reset your password</h4>
                              {/* <p>
                                Fill the below form to create a new account.
                              </p> */}
                              <input
                                type="email"
                                class="form-control mb-2"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                readOnly
                              />
                              <input
                                type="password"
                                class="form-control mb-2"
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <input
                                type="password"
                                class="form-control mb-2"
                                placeholder="Confirm Password"
                                value={password_confirmed}
                                onChange={(e) => setPasswordConfirmed(e.target.value)}
                              />

                              <div class="d-flex justify-content-between flex-sm-row flex-column">
                              <button type="submit" className="btn btn-primary">
                              Reset
                            </button>
                            
                              </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;
