import React, { useState, useEffect } from "react";

import { Table, DatePicker } from "antd";
import { getTemperatureLogList } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";
import { getActiveCompanyBranches } from "../../services/BranchAPIServices";

function TemperatureLogView({ id }) {

  const { RangePicker } = DatePicker;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [branchesList, setBranchesList] = useState([]);
  const [branch_id, setBranchId] = useState("");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");

  const columns = [
    {
      key: "branch_name",
      title: "Branch Name",
      dataIndex: "branch_name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.branch_name.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key:"freezer_name",
      title: "Freezer",
      dataIndex: "freezer_name",
    },
    {
      key:"entered_by",
      title: "Entered By",
      dataIndex: "entered_by",
    },
    {
      key: "entry_date",
      title: "Entry For",
      dataIndex: "entry_date",
      render: (value, record) => {
        return (
          <>
            {value != "" && value != null ? (<>{moment(value).format('DD/MM/YYYY')} ({record.entry_time})</>) : (<></>)}
          </>
        );
      },
    },
    {
      key: "temperature",
      title: "Temp (°C)",
      dataIndex: "temperature"
    },
    {
      key: "has_problem_text",
      title: "Has problem?",
      dataIndex: "has_problem_text"
    },
    {
      key: "problem_details",
      title: "Problem Details",
      dataIndex: "problem_details"
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    setLoading(true);
    getTemperatureLogList(branch_id,filterFrom,filterTo).then((response) => {
      setTableData(response)
      setLoading(false);
    }).catch((error) => { console.error(error) });

    getActiveCompanyBranches().then((response) => {
      setBranchesList(response)
    }).catch((error) => { console.error(error) });
  }

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setFilterTo(dateStrings[1]);
      setFilterFrom(dateStrings[0]);
    } else {
      setFilterTo("");
      setFilterFrom("");
    }
  };

  function changeDataLoad() {
    loadTableData()
  }
  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                <div className="row">
                  <div className="col-md-12"><h5>Logs List</h5> </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                  <div className="col-md-3">
                  <label className="form-label">
                    Branch
                  </label>
                  <select
                    className="form-control"
                    value={branch_id}
                    required
                    onChange={(e) => setBranchId(e.target.value)}
                  >
                    <option value="">
                      All branches
                    </option>
                    {branchesList.map((item, i) => {
                      return <option value={item.id}> {item.name}</option>;
                    })}
                  </select>
                  </div>
                  <div className="col-md-4">

                  <label for="users-list-role">Date Period</label>
                  <RangePicker
                    className="form-control"
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                    onChange={onChange}
                  />
                  </div>
                  <div className="col-md-2">
                  <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={changeDataLoad}
              >
                <i className="ft-filter"></i> filter
              </button>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="table-responsive">
                      <div className="table">
                        <Table
                          loading={loading}
                          dataSource={tableData}
                          columns={columns}
                          pagination={{
                            pageSize: 10,
                            // showSizeChanger: true,
                            showQuickJumper: true,
                            position: ["bottomCenter"],
                          }}
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default TemperatureLogView;
