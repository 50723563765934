import React, {useEffect, useRef, useState} from "react";
import Swal from "sweetalert2";
import {
    getCompanyContentTypeList,
    getSpecificCompanyData,
    updateCompanyData,
    getCompanyAttendanceType,
    getCompanyBasedComplianceList
} from "../../services/OrganizationAPIServices";
import {CONTENT_NOT_LABEL, CONTENT_TYPE, SOCIAL_MEDIA_TYPE} from "../../AppConstant";
import {Modal} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {adminUrl} from "../../UrlPaths";
import { getComplianceSections } from "../../services/ComplianceAPIServices";
import $ from "jquery";

function EditOrganization() {
    const {id} = useParams();
    const navigate = useNavigate();
    const inputRef = useRef();
    const spinner = document.getElementById("spinner");
    const [company_id, setCompanyId] = useState(id);
    const [company_full_name, setCompanyFullName] = useState("");
    const [short_name, setShortName] = useState("");
    const [website, setWebsite] = useState("");
    const [longtitue, setLongtitue] = useState("");
    const [latitude, setLatitude] = useState("");
    const [email, setEmail] = useState("");
    const [contact_no, setContactNo] = useState("");
    const [colour_theme, setColourTheme] = useState("");
    const [text_colour, setTextColour] = useState("");
    const [address, setAddress] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [contentTypeList, setContentTypeList] = useState([]);
    const [socialMediaList, setSocialMediaList] = useState([]);
    const [contentType, setContentType] = useState("");
    const [socialMediaType, setSocialMediaType] = useState("");
    const [content, setContent] = useState("");
    const [socialMedia, setSocialMedia] = useState("");
    const [selectedContent, setSelectedContent] = useState("");
    const [addedContentArray, setAddedContentArray] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState("Add Content");
    const [selectedType, setSelectedType] = useState("");
    const [selectedTypeLabel, setSelectedTypeLabel] = useState("");
    const [addedSocialMediaArray, setAddedSocialMediaArray] = useState([]);
    const [labelName, setLabelName] = useState("");
    const [image, setImage] = useState("");
    const [existedImage, setExistedImage] = useState("");
    const [status, setStatus] = useState("1");
    const [attendance_type, setAttendanceType] = useState("");
    const [attendanceTypeList, setAttendanceTypeList] = useState([]);
    const [complianceSections, setComplianceSections] = useState([]);
    let addedSocialMediaData = [];
    let addedContentData = [];

    useEffect(() => {
        $('#btn-area').hide();
        $('#btn-sm-area').hide();
        getCompanyContentTypeList().then((response) => {
            response.map((e, i) => {
                e.type == CONTENT_TYPE ? setContentTypeList(e.list) : setSocialMediaList(e.list);
            })
        });
        getCompanyAttendanceType().then((response) =>{
            setAttendanceTypeList(response.data)
        });

        getComplianceSections().then((response) => {
            setComplianceSections(response)
        }).catch((error) => { console.error(error) });

        const data = {company_id}
        getSpecificCompanyData(data).then((response) => {
            spinner.style.display = "none";

            setLabelName(response.main.company_full_name);
            setCompanyFullName(response.main.company_full_name);
            setShortName(response.main.short_name);
            if (image) {
                inputRef.current.value = "";
            }
            setEmail(response.main.email)
            setWebsite(response.main.website);
            setContactNo(response.main.contact_no);
            setLongtitue(response.main.longtitue);
            setLatitude(response.main.latitude);
            setAddress(response.main.address);
            setColourTheme(response.main.colour_theme);
            setTextColour(response.main.text_colour);
            setExistedImage(response.main.logo_path);
            setStatus(response.main.status);
            setAttendanceType(response.main.attendance_type);

            response.social_media.map((data, i) => {
                addedSocialMediaData.push({
                    "type_id": data.social_media_id,
                    'content_label': data.title,
                    'content': data.link
                })
            })

            response.content.map((data, i) => {
                addedContentData.push({"type_id": data.title_id, 'content_label': data.title, 'content': data.content})
            })
            setAddedSocialMediaArray(addedSocialMediaData);

            setAddedContentArray(addedContentData);

        });
        loadCompanyComplianceSection();
     
    }, []);
    
    function loadCompanyComplianceSection(){
        $("input[type=checkbox]").prop("checked", false);
        getCompanyBasedComplianceList(company_id).then((response) => {
            if (response.status_code == 1) {
                $.each(response.data, function (k, v) {
                  const checkid = v.compliance_section_id;
        
                  $("#" + checkid).prop("checked", true);
                });
              }
        }).catch((error) => { console.error(error) });
    }
    useEffect(() => {

    }, [addedSocialMediaData])

    const handleSubmitMainData = (e) => {
        e.preventDefault();
        const data = new FormData();
        const selected_compliance_section = [];
        $("#checkboxes input:checked").each(function () {
        var check = selected_compliance_section.includes($(this).attr("id"));
        if (check) {
        } else {
            data.append("complianceSections[]", $(this).attr("id"));
            selected_compliance_section.push($(this).attr("id"));
        }
        });
      
        data.append("id", company_id);
        data.append("company_full_name", company_full_name);
        data.append("short_name", short_name);
        data.append("address", address);
        data.append("longtitue", longtitue);
        data.append("latitude", latitude);
        data.append("contact_no", contact_no);
        data.append("email", email);
        data.append("website", website);
        data.append("text_colour", text_colour);
        data.append("colour_theme", colour_theme);
        data.append("attendance_type", attendance_type);
        data.append("logo", image);
        data.append("status", status);
        data.append("content_array", JSON.stringify(addedContentArray));
        data.append("social_media_array", JSON.stringify(addedSocialMediaArray));

        setButtonLoading(true);
        updateCompanyData(data).then((response) => {
            if (response.data.status_code == 1) {
                Swal.fire("Success", ""+response.data.message, "success").then(function() {

                    navigate("/organizations");
                });

            } else {
                Swal.fire("Warning", " " + response.data.message, "warning");
            }
            setButtonLoading(false);
        });

    };


    const contentTypeChange = (e) => {
        const value = e.target.value;
        setContentType(value);
        loadContentDisplayArea(value, e.target.selectedOptions[0].label)
    };


    const addNewContent = () => {
        setContent("");
        setModalTitle("Add Content - " + selectedTypeLabel);
        setVisible(true);
    }

    const saveContentToArray = () => {

        if (content == "" || content == null) {
            Swal.fire("Warning", "Please enter content. ", "warning");
        } else {
            setAddedContentArray([
                ...addedContentArray,
                {"type_id": selectedType, 'content_label': selectedTypeLabel, 'content': "" + content + ""}
            ]);


            loadContentDisplayArea("", selectedTypeLabel);
            setVisible(false);
            setContentType("");
            Swal.fire("Success", selectedTypeLabel + " Added Successfully..!", "success");
        }

    }

    const loadContentDisplayArea = (selectedValue, label) => {

        if (selectedValue == "" || selectedValue == 0) {
            $('#btn-area').hide();
            setSelectedContent("");
        } else {
            setSelectedTypeLabel(label)
            setSelectedType(selectedValue);

            if (addedContentArray.length > 0) {
                var isAdded = false;
                addedContentArray.map((e, i) => {
                    if (e.type_id == selectedValue) {
                        $('#btn-area').hide();
                        setSelectedContent(e.content);
                        isAdded = true;
                    }
                })

                if (!isAdded) {
                    $('#btn-area').show();
                    setSelectedContent(CONTENT_NOT_LABEL);
                }
            } else {
                $('#btn-area').show();
                setSelectedContent(CONTENT_NOT_LABEL);
            }
        }

    }

    function deleteContent(key, label, arrayType) {
        if (arrayType == CONTENT_TYPE) {
            const arrayData = [...addedContentArray];
            arrayData.splice(key, 1);
            setAddedContentArray(arrayData);

            loadContentDisplayArea("", label);
            setContentType("");
        } else {
            const arrayData = [...addedSocialMediaArray];
            arrayData.splice(key, 1);
            setAddedSocialMediaArray(arrayData);
            setSocialMediaType("");
            loadSocialMediaData("");
            setSocialMedia("");
        }

        Swal.fire("Success", label + " Removed Successfully..!", "success");
    }


    const socialMediaTypeChange = (e) => {
        const value = e.target.value;
        setSocialMediaType(value);
        loadSocialMediaData(value)
    };

    const loadSocialMediaData = (selectedId) => {

        if (selectedId == "" || selectedId == 0) {
            $('#btn-sm-area').hide();
            setSocialMedia("");
        } else {

            setSelectedType(selectedId);

            if (addedSocialMediaArray.length > 0) {
                var isAdded = false;
                addedSocialMediaArray.map((e, i) => {
                    if (e.type_id == selectedId) {
                        $('#btn-sm-area').hide();
                        setSocialMedia(e.content);
                        isAdded = true;
                    }
                })

                if (!isAdded) {
                    $('#btn-sm-area').show();
                    setSocialMedia("");
                }
            } else {
                $('#btn-sm-area').show();
                setSocialMedia("");
            }
        }

    }


    function addNewSocialMedia(e) {
        if (socialMedia == "" || socialMedia == null) {
            Swal.fire("Warning", "Please enter link. ", "warning");
        } else {
            setAddedSocialMediaArray([
                ...addedSocialMediaArray,
                {
                    "type_id": socialMediaType,
                    'content_label': document.getElementById('social_media_type').selectedOptions[0].label,
                    'content': "" + socialMedia + ""
                }
            ]);

            loadSocialMediaData("");
            setSocialMedia("");
            setSocialMediaType("");
            Swal.fire("Success", document.getElementById('social_media_type').selectedOptions[0].label + " Added Successfully..!", "success");

        }

    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h3> Edit Organization - {labelName}</h3>
                </div>
            </div>
            <section id="action-form-layout">
                <div className="row match-height">
                    <div className="col-12">
                        <div className="card collapse-icon accordion-icon-rotate">
                            <div>
                                <div id="headingCollapse11" className="card-header border-bottom pb-3">
                                    <a data-toggle="collapse" href="#collapse11" aria-expanded="true"
                                       aria-controls="collapse11" className="card-title">Main Information</a>
                                </div>
                                <div id="collapse11" role="tabpanel" aria-labelledby="headingCollapse11"
                                     className="collapse show">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form
                                                id="form-new-employee"
                                                onSubmit={(e) => {
                                                    handleSubmitMainData(e);
                                                }}
                                            >
                                                <div className="form-row">
                                                    <div className="col-md-2 col-12">
                                                        <div className="form-group position-relative">
                                                            <img alt={'logo'} className="img-fluid" width={150}
                                                                 src={`${adminUrl}${existedImage}`}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group position-relative">
                                                            <label htmlFor="company_full_name">
                                                                Company Full Name <span style={{color: "red"}}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="company_full_name"
                                                                className="form-control"
                                                                placeholder={"Company Full Name"}
                                                                name="company_full_name"
                                                                value={company_full_name}
                                                                onChange={(e) => setCompanyFullName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="form-action-7">Address <span
                                                                style={{color: "red"}}> *</span></label>
                                                            <textarea
                                                                rows="4"
                                                                className="form-control"
                                                                value={address}
                                                                onChange={(e) => setAddress(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group position-relative">
                                                            <label htmlFor="short_name">
                                                                Short Name <span style={{color: "red"}}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="short_name"
                                                                className="form-control"
                                                                placeholder="Short Name"
                                                                name="short_name"
                                                                value={short_name}
                                                                onChange={(e) => setShortName(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group position-relative">
                                                            <label htmlFor="latitude">
                                                                latitude <span style={{color: "red"}}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="latitude"
                                                                className="form-control"
                                                                placeholder="Latitude"
                                                                name="latitude"
                                                                value={latitude}
                                                                onChange={(e) => setLatitude(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group position-relative">
                                                            <label> longitude <span
                                                                style={{color: "red"}}>*</span></label>
                                                            <input
                                                                type="text"
                                                                id="longitude"
                                                                className="form-control"
                                                                placeholder="Longitude"
                                                                name="longitude"
                                                                value={longtitue}
                                                                onChange={(e) => setLongtitue(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-5 col-12">
                                                        <div className="form-group position-relative">
                                                            <label htmlFor="email">
                                                                Website
                                                                <span style={{color: "red"}}> *</span>
                                                            </label>
                                                            <input
                                                                type="url"
                                                                id="website"
                                                                className="form-control"
                                                                placeholder="Website"
                                                                name="website"
                                                                value={website}
                                                                onChange={(e) => setWebsite(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group position-relative">
                                                            <label htmlFor="email">
                                                                Email
                                                                <span style={{color: "red"}}> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="email"
                                                                className="form-control"
                                                                placeholder="Email"
                                                                name="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <div className="form-group position-relative">
                                                            <label>
                                                                Contact No <span style={{color: "red"}}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="contact_no"
                                                                className="form-control"
                                                                placeholder="Contact No (Ex:+94XXXXXXXXX)"
                                                                name="contact_no"
                                                                value={contact_no}
                                                                onChange={(e) => setContactNo(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <div className="form-group position-relative">
                                                            <label>
                                                                Company Attendance Type
                                                              
                                                            </label>
                                                            <select
                                                                className="form-control"
                                                                value={attendance_type}
                                                                onChange={(e) => setAttendanceType(e.target.value)}
                                                            >
                                                                <option value="" disabled>
                                                                    Select attendance type
                                                                </option>
                                                                {attendanceTypeList.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <option value={item.id} key={i}>
                                                                                {item.type}
                                                                            </option>
                                                                        </>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-12">
                                                        <label className="form-label">
                                                            Status <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            name="status"
                                                            id="status"
                                                            value={status}
                                                            required
                                                            onChange={(e) => setStatus(e.target.value)}
                                                        >
                                                            <option value="1"> Active</option>
                                                            <option value="0"> Inactive</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <div className="form-group position-relative">
                                                            <label>
                                                                Replace Logo
                                                            </label>
                                                            <input
                                                                type="file"
                                                                name="document"
                                                                accept="image/*"
                                                                className="form-control"
                                                                ref={inputRef}
                                                                onChange={(e) =>
                                                                    setImage(inputRef.current.files[0])
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-6">
                                                        <div className="form-group position-relative">
                                                            <label>
                                                                text colour
                                                                <span style={{color: "red"}}> *</span>
                                                            </label>
                                                            <input
                                                                type="color"
                                                                className="form-control"
                                                                value={text_colour}
                                                                onChange={(e) => setTextColour(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-6">
                                                        <div className="form-group position-relative">
                                                            <label>
                                                                theme colour
                                                                <span style={{color: "red"}}> *</span>
                                                            </label>
                                                            <input
                                                                type="color"
                                                                className="form-control"
                                                                value={colour_theme}
                                                                onChange={(e) => setColourTheme(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div id="headingCollapseCompliance" className="card-header border-bottom pb-3">
                                    <a data-toggle="collapse" href="#collapseCompliance" aria-expanded="false"
                                        aria-controls="collapseCompliance" className="card-title">Compliance Sections</a>
                                </div>
                                <div id="collapseCompliance" role="tabpanel" aria-labelledby="headingCollapseCompliance"
                                    className="collapse" aria-expanded="false">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-2 col-12"></div>
                                                <div className="col-md-8 col-12">
                                                    <div id="checkboxes">
                                                        <table class="table mb-0">
                                                            <thead style={{ background: '#fafafa' }}>
                                                                <tr>
                                                                    <th>Section</th>
                                                                    <th>Allow</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {complianceSections.map((section, i) => {
                                                                    return (
                                                                        <tr>
                                                                            <td className="d-flex align-items-center">  {section.name}</td>
                                                                            <td>
                                                                                <label className="checkbox-container">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={section.id}
                                                                                        id={section.id}
                                                                                        name={section.id}
                                                                                        defaultChecked={false}
                                                                                    />
                                                                                    <span class="checkmark"></span>
                                                                                </label>

                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div id="headingCollapse12" className="card-header border-bottom pb-3">
                                    <a data-toggle="collapse" href="#collapse12" aria-expanded="false"
                                       aria-controls="collapse12" className="card-title collapsed">Company Contents</a>
                                </div>
                                <div id="collapse12" role="tabpanel" aria-labelledby="headingCollapse12"
                                     className="collapse" aria-expanded="false">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-3 col-8">
                                                    <div className="form-group position-relative">
                                                        <label>
                                                            Content Type <span style={{color: "red"}}>*</span>
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            value={contentType}
                                                            onChange={contentTypeChange}
                                                        >
                                                            <option value="" disabled>
                                                                Select content type
                                                            </option>
                                                            {contentTypeList.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} key={i}>
                                                                            {item.title}
                                                                        </option>
                                                                    </>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 col-12">
                                                    <div className="form-group position-relative">
                                                        <label></label>
                                                        <p>{selectedContent}</p>

                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-4" id={"btn-area"}>
                                                    <button type="button" className="btn btn-info round mr-1 mb-1 mt-3"
                                                            onClick={(e) => addNewContent(e)
                                                            }>+
                                                    </button>
                                                </div>
                                                <div className="col-md-12 col-12">

                                                    {addedContentArray.map((data, index) => (
                                                        <>
                                                            <span
                                                                className="badge badge-pill badge-light mb-1 mr-2">#{data.content_label}
                                                                <button
                                                                    style={{background: "transparent", border: "none"}}
                                                                    onClick={() => deleteContent(index, data.content_label, CONTENT_TYPE)}><i
                                                                    className="ft-x"
                                                                    style={{color: "red"}}/></button></span>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div id="headingCollapse13" className="card-header border-bottom pb-3">
                                    <a data-toggle="collapse" href="#collapse13" aria-expanded="false"
                                       aria-controls="collapse13" className="card-title collapsed">Social Media Info</a>
                                </div>
                                <div id="collapse13" role="tabpanel" aria-labelledby="headingCollapse13"
                                     className="collapse" aria-expanded="false">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-3 col-12">
                                                    <div className="form-group position-relative">
                                                        <label>
                                                            Social Media Type <span style={{color: "red"}}>*</span>
                                                        </label>
                                                        <select
                                                            id={"social_media_type"}
                                                            className="form-control"
                                                            value={socialMediaType}
                                                            onChange={socialMediaTypeChange}
                                                            required
                                                        >
                                                            <option value="" disabled>
                                                                Select social media type
                                                            </option>
                                                            {socialMediaList.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.id} key={i}>
                                                                            {item.title}
                                                                        </option>
                                                                    </>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-12">
                                                    <div className="form-group position-relative">
                                                        <label>
                                                            Link <span style={{color: "red"}}>*</span>
                                                        </label>
                                                        <input
                                                            type="url"
                                                            className="form-control"
                                                            placeholder="Link"
                                                            value={socialMedia}
                                                            onChange={(e) => setSocialMedia(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1 col-4" id={"btn-sm-area"}>
                                                    <button type="button" className="btn btn-info round mr-1 mb-1 mt-3"
                                                            onClick={(e) => addNewSocialMedia(e)
                                                            }>+
                                                    </button>
                                                </div>
                                                <div className="col-md-12 col-12">

                                                    {addedSocialMediaArray.map((data, index) => (
                                                        <>
                                                            <span
                                                                className="badge badge-pill badge-light mb-1 mr-2">#{data.content_label}
                                                                <button
                                                                    style={{background: "transparent", border: "none"}}
                                                                    onClick={() => deleteContent(index, data.content_label, SOCIAL_MEDIA_TYPE)}><i
                                                                    className="ft-x"
                                                                    style={{color: "red"}}/></button></span>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary mb-3 float-right"
                                    onClick={(e) => {
                                        handleSubmitMainData(e);
                                    }}
                                    disabled={buttonLoading}
                                >
                                    {buttonLoading && (
                                        <>
                                            <i className="ft-refresh-ccw"> </i>
                                            <span>Submitting...</span>
                                        </>
                                    )}
                                    {!buttonLoading && (
                                        <>
                                            <i className="ft-check-square mr-1"></i>
                                            <span> Save changes</span>{" "}
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                maskClosable={false}
                title={modalTitle}
                visible={visible}
                okText="Save"
                onCancel={() => setVisible(false)}
                onOk={(e) => {
                    saveContentToArray(e)
                }}
            >
                <div className="row">
                    <div className="col-md-12">
                        <textarea
                            rows="10"
                            className="form-control"
                            placeholder={"Content"}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        ></textarea>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default EditOrganization;
