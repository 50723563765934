import { useNavigate } from "react-router-dom";
function BackButton() {
  const navigateBack = useNavigate();
  return (
    <>
      <button type="button" class="btn btn-light round mr-2 mb-1" onClick={() => navigateBack(-1)} ><i class="ft-chevron-left"></i>Back</button>
    </>
  );
}

export default BackButton;
