import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, TimePicker, Switch, Checkbox } from "antd";
import { freezerStatusChange, getFreezersList, getNotifySetting, submitFreezerDetails, submitSettingDetails, updateFreezerDetails } from "../../services/ComplianceAPIServices";
import { getActiveCompanyBranches } from "../../services/BranchAPIServices";
import "antd/dist/antd.css";
import {
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";

function TemperatureLog({id}) {

  const [tableData, setTableData] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [branch_id, setBranchId] = useState("");
  const [freezer_name, setFreezerName] = useState("");
  const [status, setStatus] = useState("1");
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add New Fridge Details");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [amCheck, setAmCheck] = useState(false);
  const [pmCheck, setPmCheck] = useState(false);
  const [eveningCheck, setEveningCheck] = useState(false);
  const [amDisable, setAmDisable] = useState(true);
  const [pmDisable, setPmDisable] = useState(true);
  const [eveningDisable, setEveningDisable] = useState(true);
  const [amFrom, setAmFrom] = useState("");
  const [amTo, setAmTo] = useState("");
  const [pmFrom, setPmFrom] = useState("");
  const [pmTo, setPmTo] = useState("");
  const [eveningFrom, setEveningFrom] = useState("");
  const [eveningTo, setEveningTo] = useState("");
  const [settingDataset, setSettingDataset] = useState([]);
  const [amRange, setAmRange] = useState(null);
  const [selected_freezerId, setSelectedFreezerId] = useState("0");

  const timeFormat = 'HH:mm';
  const columns = [
    {
      key: "branch_name",
      title: "Branch Name",
      dataIndex: "branch_name"
    },
    {
      key: "freezer_name",
      title: "Freezer Name",
      dataIndex: "freezer_name"
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                {record.status == "1" ? (
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    onClick={() => handleClick("0", record.id)}
                  />
                ) : (
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultUnChecked
                    onClick={() => handleClick("1", record.id)}
                  />
                )}
              </div>
              <div>
                <button
                  className="badge badge-pill bg-light-info ml-2"
                  style={{ border: '2px solid #dadada' }}
                onClick={() => Edit(record)}
                >
                  <i className="ft-edit"></i>
                </button>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
    loadSettingsData();
  }, []);


  const loadTableData = () => {
    setLoading(true);
    getFreezersList().then((response) => {
      setTableData(response)
      setLoading(false);

    }).catch((error) => { console.error(error) });

    getActiveCompanyBranches().then((response) => {
      setBranchesList(response)
    }).catch((error) => { console.error(error) });
  }

  const loadSettingsData = () => {
    getNotifySetting(id).then((response) => {
      let notifyData = JSON.parse(response.data.data.notify_settings);


      // if (Array.isArray(notifyData) ) {


      let result = Object.entries(notifyData).map(([k, v]) => ({ [k]: v }));
      result.forEach((item) => {
        var key = Object.keys(item)[0];
      
        var timeArray = [];
        timeArray.push(moment(item[key].from, "HH:mm").format('HH:mm'), moment(item[key].to, "HH:mm").format('HH:mm'));
        timeOnChange(item[key].from, timeArray, item[key].time_period)
        if (item[key].time_period == 'am') {
          setAmCheck(true); setAmDisable(false); setAmFrom(item[key].from); setAmTo(item[key].to);
        }
        if (item[key].time_period == 'pm') {
          setPmCheck(true); setPmDisable(false); setPmFrom(item[key].from); setPmTo(item[key].to);
        }
        if (item[key].time_period == 'evening') {
          setEveningCheck(true); setEveningDisable(false); setEveningFrom(item[key].from); setEveningTo(item[key].to);
        }
      });


    }).catch((error) => { console.error(error) });
  }

  const handleNewFreezerModal = () => {
    setConfirmLoading(false);
    setBranchId("");
    setFreezerName("");
    setSelectedFreezerId("0");
    setModalTitle("Add New Fridge Details");
    setVisible(true);
  };

  const handleSubmit = (e) => {
    if(selected_freezerId == '0'){
      const data = { branch_id, freezer_name, branch_id, status };
      setConfirmLoading(true);
      submitFreezerDetails(data).then((response) => {
  
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            setVisible(false);
            loadTableData();
          });
  
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });
    }else{
      freezeUpdate();
    }

  }

  const handleClick = (status, freezerID) => {
    const data = { status: status, id: freezerID };
    freezerStatusChange(freezerID, data).then((response) => {
      if (response.data.status_code == 1) {
        Swal.fire("Success", "" + response.data.message, "success");
      } else {
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
      loadTableData();
    });
  }

  const Edit = (data) => {
    setConfirmLoading(false);
    setSelectedFreezerId(data.id);
    setFreezerName(data.freezer_name);
    setBranchId(data.branch_id);
    setModalTitle("Edit Freezer Details");
    setVisible(true);

  };

  const freezeUpdate = () => {
    const dataset = { branch_id, freezer_name };
    setConfirmLoading(true);
    updateFreezerDetails(selected_freezerId,dataset).then((response) => {
      if (response.data.status_code == 1) {
        Swal.fire("Success", "" + response.data.message, "success").then(function () {
          setConfirmLoading(false);
          setSelectedFreezerId("0");
          setVisible(false);
          loadTableData();
        });

      } else {
        setConfirmLoading(false);
        Swal.fire("Warning", " " + response.data.message, "warning");
      }
    }).catch((error) => {
      setConfirmLoading(false);
      Swal.fire("Warning", "" + error.response.data.message, "warning");
    });
  }


  const onTimeChangeCheckbox = (e, time) => {

    time == 'am' ? setAmCheck(e.target.checked) : time == 'pm' ? setPmCheck(e.target.checked) : setEveningCheck(e.target.checked)
    time == 'am' ? setAmDisable(!e.target.checked) : time == 'pm' ? setPmDisable(!e.target.checked) : setEveningDisable(!e.target.checked);

  };


  const timeOnChange = (dates, dateStrings, time_period) => {

    if (dates) {
      
      setAmRange(dateStrings)
      if (time_period == 'am') {
        setAmFrom(dateStrings[0]);
        setAmTo(dateStrings[1]);
      } else if (time_period == 'pm') {
        setPmFrom(dateStrings[0]);
        setPmTo(dateStrings[1]);
      } else {
        setEveningFrom(dateStrings[0]);
        setEveningTo(dateStrings[1]);
      }

    } else {
      if (time_period == 'am') {
        setAmFrom("");
        setAmTo("");
      } else if (time_period == 'pm') {
        setPmTo("");
        setPmFrom("");
      } else {
        setEveningFrom("");
        setEveningTo("");
      }
    }
  };

  const submitSettings = (e) => {


    if (!amCheck && !pmCheck && !eveningCheck) {
      Swal.fire("Warning", "Please select at least one time period.", "warning");
    } else if (amCheck && (amTo == "" || amFrom == "")) {
      Swal.fire("Warning", "Please select AM time period.", "warning");
    } else if (pmCheck && (pmTo == "" || pmFrom == "")) {
      Swal.fire("Warning", "Please select PM time period.", "warning");
    } else if (eveningCheck && (eveningFrom == "" || eveningTo == "")) {
      Swal.fire("Warning", "Please select evening time period.", "warning");
    } else {
      var json_string = '{';
      let array_count = 0;

      if (amCheck) {
        var comma_set = array_count != 0 ? ',' : '';
        json_string += '' + comma_set + '"' + array_count + '":{"time_period":"am","from":"' + amFrom + '","to":"' + amTo + '"}';
        array_count = array_count + 1;
      }
      if (pmCheck) {
        var comma_set = array_count != 0 ? ',' : '';
        json_string += '' + comma_set + '"' + array_count + '":{"time_period":"pm","from":"' + pmFrom + '","to":"' + pmTo + '"}';
        array_count = array_count + 1;
      }
      if (eveningCheck) {
        var comma_set = array_count != 0 ? ',' : '';
        json_string += '' + comma_set + '"' + array_count + '":{"time_period":"evening","from":"' + eveningFrom + '","to":"' + eveningTo + '"}';
        array_count = array_count + 1;
      }
      json_string += '}';

      const data_json = { compliance_type: id, notify_settings: json_string }
      submitSettingDetails(data_json).then((response) => {

        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadSettingsData();
          });
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });

    }

  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-7"><h6>Set Reading Intervals</h6></div>
                    <div className="col-md-5">
                      <div className="col-md-12 p-1"><span>Reading Time</span></div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" style={{ gap: '38px' }}>
                        <div><Checkbox
                          checked={amCheck}
                          onChange={(e) => onTimeChangeCheckbox(e, "am")}
                        >
                          <b>AM</b>
                        </Checkbox></div>
                        <div>
                          <small className="text-muted">{amFrom} - {amTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} disabled={amDisable} onChange={(e, x) => timeOnChange(e, x, "am")} /></div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" style={{ gap: '38px' }}>
                        <div><Checkbox
                          checked={pmCheck}
                          onChange={(e) => onTimeChangeCheckbox(e, "pm")}
                        >
                          <b>PM</b>
                        </Checkbox ></div>
                        <div>
                          <small className="text-muted">{pmFrom} - {pmTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} disabled={pmDisable} onChange={(e, x) => timeOnChange(e, x, "pm")} /></div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" style={{ gap: '3px' }}>
                        <div><Checkbox
                          checked={eveningCheck}
                          onChange={(e) => onTimeChangeCheckbox(e, "evening")}
                        >
                          <b>Evening</b>
                        </Checkbox></div>
                        <div><small className="text-muted">{eveningFrom} - {eveningTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} disabled={eveningDisable} onChange={(e, x) => timeOnChange(e, x, "evening")} /></div>
                      </div>

                      <div className="col-md-12 p-1"> <button
                        type="button"
                        className="btn btn-primary btn-sm float-right"
                        onClick={(e) => submitSettings(e)}
                      >
                        <i class="fa fa-check"></i> Save
                      </button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-8"><h5>Manage Fridge Details</h5></div>
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="btn btn-primary mb-3  float-right"
                      onClick={(e) => handleNewFreezerModal(e)}
                    >
                      Add New <i class="ft-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-content">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">
                Select Branch Name <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={branch_id}
                required
                onChange={(e) => setBranchId(e.target.value)}
              >
                <option value="" disabled>
                  Select branch
                </option>
                {branchesList.map((item, i) => {
                  return <option value={item.id}> {item.name}</option>;
                })}
              </select>
            </div>

            <div className="col-md-12">
              <label className="form-label">
                Fridge Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={freezer_name}
                onChange={(e) => setFreezerName(e.target.value)}
                required
              />

            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default TemperatureLog;
