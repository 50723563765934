import React, { useState, useEffect } from "react";

import { Table, Input, Image } from "antd";
import { getAllWasteList } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";
import { adminUrl } from "../../UrlPaths";

function WasteLogView({ id }) {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");


  const columns = [
    {
      key: "branch_name",
      title: "Branch Name",
      dataIndex: "branch_name",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          record.branch_name.toLowerCase().includes(value.toLowerCase()) ||
          record.entered_by.toLowerCase().includes(value.toLowerCase()) ||
          record.category_name.toLowerCase().includes(value.toLowerCase()) ||
          record.method_text.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      key: "entered_by",
      title: "Entered By",
      dataIndex: "entered_by",
    },
    {
      key: "created_at",
      title: "Log at",
      dataIndex: "created_at",
      render: (value, record) => {
        return (
          <>
            {value != "" && value != null ? (<>{moment(value).format('DD/MM/YYYY HH:mm')}</>) : (<></>)}
          </>
        );
      },
    },
    {
      key: "category_name",
      title: "Category",
      dataIndex: "category_name",
      render: (value, record) => {
        return (
          <>
            <div className="d-flex flex-row justify-content-between">
              <div>
                <span>{value}</span>
              </div>
              <div>
                <Image
                  width={60}
                  src={`${adminUrl}${record.image}`}
                />
              </div>

            </div>
          </>
        );
      },
    },
    {
      key: "method_text",
      title: "Discared Method",
      dataIndex: "method_text"
    },
    {
      key: "qty",
      title: "Qty",
      dataIndex: "qty"
    },
    {
      key: "comment",
      title: "Comment",
      dataIndex: "comment"
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = () => {
    setLoading(true);
    getAllWasteList().then((response) => {
      setTableData(response)
      setLoading(false);
    }).catch((error) => { console.error(error) });
  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6"><h5>Logs List</h5> </div>
                    <div className="col-md-6"> <Input.Search
                      placeholder="Search..."
                      style={{ marginBottom: 5 }}
                      value={searchedText}
                      onSearch={(value) => {
                        setSearchedText(value);
                      }}
                      onChange={(e) => {
                        setSearchedText(e.target.value);
                      }}
                    /></div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <div className="table">
                          <Table
                            loading={loading}
                            dataSource={tableData}
                            columns={columns}
                            pagination={{
                              pageSize: 10,
                              showSizeChanger: true,
                              position: ["bottomCenter"],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WasteLogView;
