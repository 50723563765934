import { Routes, Route} from "react-router-dom";
import React, { useContext } from "react";
import { UserContext } from "./context/Auth.context";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Login from "./pages/Login";
import Designation from "./pages/master-files/Designation";
import Teams from "./pages/master-files/Teams";
import NewEmployee from "./pages/employees/NewEmployee";
import ProfileList from "./pages/employees/ProfileList";
import Profile from "./pages/employees/Profile";
import EditEmployee from "./pages/employees/EditEmployee";
import Payroll from "./pages/Payroll";
import Leave from "./pages/Leave";
import Attendance from "./pages/Attendance";
import Message from "./pages/Message";
import Dashboard from "./pages/Dashboard";
import Permission from "./pages/Permission";
import UserProfile from "./pages/UserProfile";
// import { PermissionContext } from "./context/PermissionCheck.context";
import Announcement from "./pages/Announcement";
import Policy from "./pages/Policy";
import PolicyCategory from "./pages/PolicyCategory";
import Organization from "./pages/Organizations/Organization";
import NewOrganization from "./pages/Organizations/NewOrganization";
import EditOrganization from "./pages/Organizations/EditOrganization";
import DocumentVerify from "./pages/DocumentVerify";
import RequestAccountDeletion from "./pages/RequestAccountDeletion";
import Branch from "./pages/master-files/Branch";
import ComplianceManagement from "./pages/compliance/ComplianceManagement";
import WasteManagement from "./pages/compliance/WasteManagement";
import AlcoholTraining from "./pages/compliance/AlcoholTraining";
import AgeManagement from "./pages/compliance/AgeManagement";
import TemperatureManagement from "./pages/compliance/TemperatureManagement";
import CCTVLogManagement from "./pages/compliance/CCTVLogManagement";
import HealthAndSafetyIncidentView from "./pages/compliance/HealthAndSafetyIncidentView";

export const AuthenticatedRoutes = () => {
  const { user, setUser } = useContext(UserContext);
  // const permission = useContext(PermissionContext);
  return (
    <>
      <Routes>
        <Route path="/designations" element={<Designation />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/new-employee" element={<NewEmployee />} />
        <Route path="/existing-employees" element={<ProfileList />} />
        <Route path="/employee-view/:id" element={<Profile />} />
        <Route path="/employee-edit/:id" element={<EditEmployee />} />
        <Route path="/payroll" element={<Payroll />} />
        <Route path="/leave" element={<Leave />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/messages" element={<Message />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/permission" element={<Permission />} />
        <Route path="/account" element={<UserProfile />} />
          <Route path="/announcements" element={<Announcement />} />
          <Route path="/policies" element={<Policy />} />
          <Route path="/policies/:id" element={<PolicyCategory />} />
          <Route path="/organizations" element={<Organization />} />
          <Route path="/new-organization" element={<NewOrganization />} />
          <Route path="/organization-edit/:id" element={<EditOrganization />} />
          <Route path="/document-verification" element={<DocumentVerify />} />
          <Route path="/branches" element={<Branch/>}/>
          <Route path="/compliance" element={<ComplianceManagement/>}/>
          <Route path="/temperature-log" element={<TemperatureManagement/>}/>
          <Route path="/age-verification" element={<AgeManagement/>}/>
          <Route path="/waste-management" element={<WasteManagement/>}/>
          <Route path="/alcohol-training" element={<AlcoholTraining/>}/>
          <Route path="/cctv-log-management" element={<CCTVLogManagement/>}/>
          <Route path="/health-and-safety" element={<HealthAndSafetyIncidentView/>}/>
      </Routes>
    </>
  );
};

export const UnauthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/password/reset/:token/:mail" element={<ResetPassword />} />
      <Route path="/delete-user" element={<RequestAccountDeletion />} />
    </Routes>
  );
};


export const UnAuthorizedRoutes = () => {

  return (
    <>
      <h1 class="mt-4">401 - Access Denied!</h1>
      <div class="w-75 error-text mx-auto mt-4">
        <p>Sorry You don't have permission to this page.</p>
      </div>
    </>
  );
};
