import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getComplianceSections() {
  try {
    const response = await axios.get(
      APIEndpoint+'/compliance/list',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getComplianceAccessSections() {
  try {
    const response = await axios.get(
      APIEndpoint+'/compliance/company/sections',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function submitSettingDetails(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/storeComplianceSettings`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}


export async function getNotifySetting(id) {
  try {
    const response = await axios.get(
        `${APIEndpoint}/notifySettings/${id}`,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}


export async function getFreezersList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/freezers',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function submitFreezerDetails(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/freezers/add`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function freezerStatusChange(id,data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/freezers/${id}/status`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function updateFreezerDetails(id,data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/freezers/${id}/update`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function getCCtvLogList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/cctv-logs',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function submitCCTVLog(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/cctv-logs/add`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}


export async function getDateAndBranchBasedLogs(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/cctv-logs/get/branch-date`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}


export async function getAllQuizList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/quiz/list',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function quizPublishToEmployee(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/quiz/publish`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function createNewQuiz(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/quiz/create`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function getQuizAllQuestionAndAnswers($quizId) {
  try {
    const response = await axios.get(
      APIEndpoint+`/quiz/question-answers/${$quizId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function submitQuizQAndA(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/quiz/question/create`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function getEmployeeQuizAttemptaList(quizId) {
  try {
    const response = await axios.get(
      APIEndpoint+`/quiz/employee/attempts?quiz_id=${quizId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAttemptedQuestionAndAnswers(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/quiz/attempt/details/get`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function getPublishedQuizList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/quiz/published/list',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function publishQuizAttemptFinalResults(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/quiz/attempt/result/publish`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

export async function saveTextAnswerResults(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/quiz/question/result/decision`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {

    return error;
  }
}

// age section
export async function getAllAgeVerificationList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/compliance/age/all-list',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}


// waste section
export async function getAllWasteList() {
  try {
    const response = await axios.get(
      APIEndpoint+'/compliance/waste/list',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}


// temperature section
export async function getTemperatureLogList(branchId,from,to) {
  try {
    const response = await axios.get(
      APIEndpoint+`/temperature-logs?branch_id=${branchId}&start_date=${from}&end_date=${to}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

// health & safty section
export async function getHealthAndSafetyReports(branchId,from,to) {
  try {
    const response = await axios.get(
      APIEndpoint+`/health-and-safety?branch_id=${branchId}&start_date=${from}&end_date=${to}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}