import React, { useState, useEffect } from "react";
import { logoutAPI } from "../services/AuthAPIServices";
import { Navigate, useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { displayUrl } from "../UrlPaths";
import { getNotifications } from "../services/SettingsAPIServices";

function Navbar() {

  let navigate2 = useNavigate();
  const userName = localStorage.getItem("hms_user");
  const img_path = localStorage.getItem("hms_img_path");
  const userID = localStorage.getItem("hms_user_id");
  const [count, setCount] = useState(0);
  const [notificationsList, setNotificationsList] = useState([]);

  const logoutEvent = (e) => {
    Swal.fire({
      title: "Are you sure logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutAPI().then((response) => {
          if (response.status_code == "1") {
            localStorage.removeItem("hms_access_token");
            localStorage.setItem("hms_authenticated", false);
            localStorage.removeItem("hms_user");
            localStorage.removeItem("hms_user_role");
            localStorage.removeItem("hms_img_path");
            localStorage.removeItem("hms_company_logo");
            // localStorage.removeItem("hms_user_rights");
            localStorage.removeItem("hms_user_id");

            navigate2("/");
            navigate2(0);
          }
        });
      }
    });
  };
  const navigateToLogin = () => {
    return <Navigate to="/" replace={true} />;
  };


  useEffect(() => {

    const fetchData = async () => {
      try {
        getNotifications().then((response) =>{
          if(response.status_code == 1){
             setCount(response.data.length);
             setNotificationsList(response.data)
          }else{
            setCount(0);
            setNotificationsList([])
          }
         
         
        });
      } catch (error) {
      }
    };
    fetchData();
    // Polling interval (every 5 seconds)
    const pollingInterval = setInterval(fetchData, 5000);
    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(pollingInterval);
  }, []);


  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light header-navbar navbar-fixed"
        style={{ background: "white" }}
      >
        <div className="container-fluid navbar-wrapper">
          <div className="navbar-header d-flex">
            <div
              className="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
              data-toggle="collapse"
            >
              <i className="ft-menu font-medium-3"></i>
            </div>
            <ul className="navbar-nav"></ul>
          </div>
          <div className="navbar-container">
            <div
              className="collapse navbar-collapse d-block"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="dropdown nav-item">
                  <a className="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="dropdownBasic1" href="javascript:;" data-toggle="dropdown">
                    <i class="ft-bell font-medium-3"></i>
                    <span className={count > 0 ? 'notification badge badge-pill badge-danger' : 'notification badge badge-pill badge-success'}>{count}</span>
                    </a>
                  <ul className="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

                    <li className="scrollable-container">
                    {notificationsList.map((notification, ind) => (
                      <Link className="d-flex justify-content-between" to={`${notification.link}`}>
                        <div className="media d-flex align-items-center">
                          <div className="media-body">
                            <h6 className="mb-1"><span>{notification.name}</span></h6>
                            <h6 className="noti-text font-small-3 m-0">
                            {notification.message}
                              </h6>
                          </div>
                        </div>
                      </Link>
                    ))}
                    </li>
                  </ul>
                </li>
                <li className="dropdown nav-item mr-1">
                  <Link
                    to={`/account`}
                    className="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
                  >
                    <div className="user d-md-flex d-none mr-2">
                      <span className="text-right">{userName}</span>
                      <span className="text-right text-muted font-small-3">
                        Available
                      </span>
                    </div>
                    <img
                      className="avatar"
                      src={`${displayUrl}/${img_path}`}
                      alt="avatar"
                      height="35"
                      width="35"
                    />
                  </Link>
                </li>
                <li>
                  {" "}
                  <button
                    className="d-flex align-items-center"
                    style={{ background: "none", border: "none" }}
                    type="button"
                    onClick={(e) => {
                      logoutEvent();
                    }}
                  >
                    <i className="ft-power mr-2"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
