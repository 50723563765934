import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import WasteSetting from "../../components/compliance/WasteSetting";
import WasteLogView from "../../components/compliance/WasteLogView";
import BackButton from "../../components/BackButton";

function WasteManagement() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const hash = location.hash;
  const [actionText, setActionText] = useState(hash.replace('#',''));

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <BackButton/>
          <h3> Waste Management - {actionText.charAt(0).toUpperCase() + actionText.slice(1).toLowerCase()}</h3>
        </div>
      </div>
      {actionText == 'setting' ? (<WasteSetting id={id} />) : (<WasteLogView id={id} />)}

    </>
  );
}
export default WasteManagement;
