import React, { useState, useEffect } from "react";
import TemperatureLog from "../../components/compliance/TemperatureLog";
import { useLocation,useNavigate } from "react-router-dom";
import TemperatureLogView from "../../components/compliance/TemperatureLogView";
import BackButton from "../../components/BackButton";

function TemperatureManagement() {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const hash = location.hash;
  const [actionText, setActionText] = useState(hash.replace('#',''));

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
        <BackButton />
          <h3> Temperature Management - {actionText.charAt(0).toUpperCase() + actionText.slice(1).toLowerCase()}</h3>
        </div>
      </div>
      {actionText == 'setting' ? (<TemperatureLog id={id} />) : (<TemperatureLogView id={id} />)}
    </>
  );
}
export default TemperatureManagement;
