export const CONTENT_TYPE = "content";
export const SOCIAL_MEDIA_TYPE = "social_media";
export const CONTENT_NOT_LABEL = "Not added content. Please add.";
export const GLOBAL_ADMIN_ROLE = 1;
export const FINANCE_USER_ROLE = 6;
export const SHORT_LEAVE_TYPE_ID = 4;
export const LEAVE_STATUS = [{"id":"APPROVED","name":"APPROVED"}];
export const QUIZ_PASS = 'Pass';
export const QUIZ_FAILED = 'Retake';
export const EMP_EDUCATION_TYPE = 'Education';
export const EMP_CERTIFICATION_TYPE = 'Certification';
export const EMP_PASSPORT_TYPE = 'Passport';
export const EMP_VISA_TYPE = 'Visa';
export const EMP_COS_TYPE = 'COS';
export const EMP_RTW_TYPE = 'RTW';