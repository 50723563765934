import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Table, Modal, Input, Button, TimePicker, Switch } from "antd";
import { SearchOutlined, CheckOutlined, CloseOutlined, } from "@ant-design/icons";
import { getAllCompanyBranches } from "../../services/BranchAPIServices";
import { updateBranchDetails } from "../../services/BranchAPIServices";
import { createBranch } from "../../services/BranchAPIServices";

function Branch() {
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");
  const [contact_no, setContactNo] = useState("");
  const [branch_id, setBranchId] = useState("0");
  const [modalTitle, setModalTitle] = useState("Add New Branch");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);


  const columns = [
    {
      key: "name",
      title: "Branch",
      dataIndex: "name"
    },
    {
      key: "address",
      title: "Address",
      dataIndex: "address"
    },
    {
      key: "contact_number",
      title: "Contact Number",
      dataIndex: "contact_number"
    },
    {
      key: "status",
      title: "Status",
      render: (record) => {
        return (
          <>
            {" "}
            <div className="flex">
              {record.status == 1 ? (
                <span className="badge bg-light-success">Active</span>
              ) : (
                <span className="badge bg-light-danger">Inactive</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      title: "",
      render: (record) => {
        return (
          <>
            <div className="d-flex">
              <button
                className="badge badge-pill bg-light-info mb-1 mr-2"
                style={{ border: '2px solid #dadada' }}
                onClick={() => Edit(record)}
              >
                <i className="ft-edit"></i>
              </button>

            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    loadTableData();
  }, []);


  const loadTableData = () => {
    setVisible(false);
    setLoading(true);
    getAllCompanyBranches().then((response) => {
      setTableData(response)
      setLoading(false);

    }).catch((error) => { console.error(error) });
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    let timeValidation = true;
    let inputValidation = true;

    if (name == "" || name == null) {
      Swal.fire("Warning", "Please enter branch name", "warning");
      inputValidation = false;
    } else if (address == "" || address == null) {
      Swal.fire("Warning", "Please enter address", "warning");
      inputValidation = false;
    }
    const data = { name, address, status, branch_id, contact_no };

    if (branch_id == "0" && timeValidation && inputValidation) {
      setConfirmLoading(true);
      createBranch(data).then((response) => {
     
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            loadTableData();
          });

        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });
    } else if (timeValidation && inputValidation) {
      setConfirmLoading(true);
      updateBranchDetails(data).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            setConfirmLoading(false);
            loadTableData();
          });
        } else {
          setConfirmLoading(false);
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        setConfirmLoading(false);
        Swal.fire("Warning", "" + error.response.data.message, "warning");
        console.error(error)
      });
    }
  };

  const handleNewBranchForm = (e) => {
    setConfirmLoading(false);
    setBranchId("0");
    setName("");
    setAddress("");
    setContactNo("");
    setStatus("1");
    setModalTitle("Add New Branch");
    setVisible(true);

  };

  const Edit = (data) => {
    setConfirmLoading(false);
    setBranchId(data.id);
    setName(data.name);
    setContactNo(data.contact_number);
    setStatus(data.status);
    setModalTitle("Edit Branch");
    setAddress(data.address);
    setVisible(true);

  };

  return (
    <>
      <div className="row">
        <div className="col-8">
          <h3> Branches </h3>
        </div>
        <div className="col-4">
          <button
            type="button"
            className="btn btn-primary mb-3 d-block  float-right"
            onClick={(e) => handleNewBranchForm(e)}
          >
            Add New <i class="ft-plus ml-2"></i>
          </button>
        </div>
      </div>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table">
                      <Table
                        loading={loading}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          pageSize: 10,
                          showSizeChanger: true,
                          position: ["bottomCenter"],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={modalTitle}
        visible={visible}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        onOk={(e) => {
          handleSubmit(e);
        }}
      >
        <form>
          <div className="row">
            <div className="col-md-8">

              <label className="form-label">
                Branch Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />

            </div>
            <div className="col-md-4">
              <label className="form-label">
                Status <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                value={status}
                required
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="1"> Active</option>
                <option value="0"> Inactive</option>
              </select>

            </div>

            <div className="col-md-8">
              <label className="form-label">
                Address <span style={{ color: "red" }}>*</span>
              </label>
              <textarea  
                className="form-control"
                placeholder="Address"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                rows={3}
                required></textarea>
            </div>

            <div className="col-md-4">
              <label className="form-label">
                Contact no <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={contact_no}
                onChange={(e) => setContactNo(e.target.value)}
                required
              />
            </div>

          </div>
        </form>
      </Modal>
    </>
  );
}
export default Branch;
