import axios from "axios";
import { APIEndpoint,token } from '../UrlPaths';



export async function getAllCompanyBranches() {
  try {
    const response = await axios.get(
      APIEndpoint+'/getAllCompanyBranches',
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getActiveCompanyBranches() {
  try {
    const response = await axios.get(
        APIEndpoint+'/getActiveCompanyBranches',
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}


export async function createBranch(data) {
  try {
    const response = await axios.post(
      `${APIEndpoint}/createBranch`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
    return error;
  }
}


export async function updateBranchDetails(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/updateBranchDetails`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
    return error;
  }
}

export async function changeBranchStatus(data) {
  try {
    const response = await axios.post(
        `${APIEndpoint}/changeBranchStatus`, data,
        { headers: { 'Authorization': `Bearer ${token}`} });

    return response;
  } catch (error) {
    return error;
  }
}

