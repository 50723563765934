import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { TimePicker, Switch, Checkbox } from "antd";
import { getNotifySetting, submitSettingDetails } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";

function WasteSetting({id}) {

  const [amCheck, setAmCheck] = useState(false);
  const [pmCheck, setPmCheck] = useState(false);
  const [amDisable, setAmDisable] = useState(true);
  const [pmDisable, setPmDisable] = useState(true);
  const [amFrom, setAmFrom] = useState("");
  const [amTo, setAmTo] = useState("");
  const [pmFrom, setPmFrom] = useState("");
  const [pmTo, setPmTo] = useState("");
  const [amRange, setAmRange] = useState(null);


  const timeFormat = 'HH:mm';

  useEffect(() => {

    loadSettingsData();
  }, []);



  const loadSettingsData = () => {
    getNotifySetting(id).then((response) => {
      let notifyData = JSON.parse(response.data.data.notify_settings);

      let result = Object.entries(notifyData).map(([k, v]) => ({ [k]: v }));
      result.forEach((item) => {
        var key = Object.keys(item)[0];
     
        var timeArray = [];
        timeArray.push(moment(item[key].from, "HH:mm").format('HH:mm'), moment(item[key].to, "HH:mm").format('HH:mm'));
        timeOnChange(item[key].from, timeArray, item[key].time_period)
        if (item[key].time_period == 'am') {
          setAmCheck(true); setAmDisable(false); setAmFrom(item[key].from); setAmTo(item[key].to);
        }
        if (item[key].time_period == 'pm') {
          setPmCheck(true); setPmDisable(false); setPmFrom(item[key].from); setPmTo(item[key].to);
        }
       
      });


    }).catch((error) => { console.error(error) });
  }



  const onTimeChangeCheckbox = (e, time) => {
    time == 'am' ? setAmCheck(e.target.checked) : setPmCheck(e.target.checked);
    time == 'am' ? setAmDisable(!e.target.checked) : setPmDisable(!e.target.checked);
  };


  const timeOnChange = (dates, dateStrings, time_period) => {

    if (dates) {
      setAmRange(dateStrings)
      if (time_period == 'am') {
        setAmFrom(dateStrings[0]);
        setAmTo(dateStrings[1]);
      } else if (time_period == 'pm') {
        setPmFrom(dateStrings[0]);
        setPmTo(dateStrings[1]);
      } 

    } else {
      if (time_period == 'am') {
        setAmFrom("");
        setAmTo("");
      } else if (time_period == 'pm') {
        setPmTo("");
        setPmFrom("");
      } 
    }
  };

  const submitSettings = (e) => {


    if (!amCheck && !pmCheck) {
      Swal.fire("Warning", "Please select at least one time period.", "warning");
    } else if (amCheck && (amTo == "" || amFrom == "")) {
      Swal.fire("Warning", "Please select AM time period.", "warning");
    } else if (pmCheck && (pmTo == "" || pmFrom == "")) {
      Swal.fire("Warning", "Please select PM time period.", "warning");
    } else {
      var json_string = '{';
      let array_count = 0;

      if (amCheck) {
        var comma_set = array_count != 0 ? ',' : '';
        json_string += '' + comma_set + '"' + array_count + '":{"time_period":"am","from":"' + amFrom + '","to":"' + amTo + '"}';
        array_count = array_count + 1;
      }
      if (pmCheck) {
        var comma_set = array_count != 0 ? ',' : '';
        json_string += '' + comma_set + '"' + array_count + '":{"time_period":"pm","from":"' + pmFrom + '","to":"' + pmTo + '"}';
        array_count = array_count + 1;
      }
      
      json_string += '}';

      const data_json = { compliance_type: id, notify_settings: json_string }
      submitSettingDetails(data_json).then((response) => {

        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadSettingsData();
          });
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });

    }

  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <center>
                    <div className="col-md-5">
                      <div className="col-md-12 p-1"><span>Reading Time</span></div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" style={{ gap: '38px' }}>
                        <div><Checkbox
                          checked={amCheck}
                          onChange={(e) => onTimeChangeCheckbox(e, "am")}
                        >
                          <b>AM</b>
                        </Checkbox></div>
                        <div>
                          <small className="text-muted">{amFrom} - {amTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} disabled={amDisable} onChange={(e, x) => timeOnChange(e, x, "am")} /></div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" style={{ gap: '38px' }}>
                        <div><Checkbox
                          checked={pmCheck}
                          onChange={(e) => onTimeChangeCheckbox(e, "pm")}
                        >
                          <b>PM</b>
                        </Checkbox ></div>
                        <div>
                          <small className="text-muted">{pmFrom} - {pmTo}</small>
                          <TimePicker.RangePicker use24Hours format={timeFormat} disabled={pmDisable} onChange={(e, x) => timeOnChange(e, x, "pm")} /></div>
                      </div>

                      <div className="col-md-12 p-1"> <button
                        type="button"
                        className="btn btn-primary btn-sm float-right"
                        onClick={(e) => submitSettings(e)}
                      >
                        <i class="fa fa-check"></i> Save
                      </button></div>
                    </div>
                    </center>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default WasteSetting;
