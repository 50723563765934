import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import AlcoholTrainingSetting from "../../components/compliance/AlcoholTrainingSetting";
import AlcoholTrainingView from "../../components/compliance/AlcoholTrainingView";
import BackButton from "../../components/BackButton";

function AlcoholTraining() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const hash = location.hash;
  const [actionText, setActionText] = useState(hash.replace('#',''));
 
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
            <BackButton/>
          <h3> Alcohol Training Management - {actionText.charAt(0).toUpperCase() + actionText.slice(1).toLowerCase()}</h3>
        </div>
      </div>
      {actionText == 'setting' ? (<AlcoholTrainingSetting id={id} />) : (<AlcoholTrainingView id={id}/>)}
    </>
  );
}
export default AlcoholTraining;
