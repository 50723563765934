import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";
import CCTVLogSetting from "../../components/compliance/CCTVLogSetting";
import CCTVLogView from "../../components/compliance/CCTVLogView";
import BackButton from "../../components/BackButton";

function CCTVLogManagement() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const hash = location.hash;
  const [actionText, setActionText] = useState(hash.replace('#',''));
 
  return (
    <>
      <div className="row">
        <div className="col-12 d-flex align-items-center">
          <BackButton/>
          <h3> CCTV Log Management - {actionText.charAt(0).toUpperCase() + actionText.slice(1).toLowerCase()}</h3>
        </div>
      </div>
      {actionText == 'setting' ? (<CCTVLogSetting id={id} />) : (<CCTVLogView/>)}
    </>
  );
}
export default CCTVLogManagement;
