import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { Select, DatePicker } from "antd";
import { getNotifySetting, submitSettingDetails } from "../../services/ComplianceAPIServices";
import "antd/dist/antd.css";
import moment from "moment";

function CCTVLogSetting({ id }) {

  const { Option } = Select;
  const [startsFrom, setstartsFrom] = useState("");
  const [interval, setIntervals] = useState("");

  useEffect(() => {
    loadSettingsData();
  }, []);


  const loadSettingsData = () => {
    getNotifySetting(id).then((response) => {

      let notifyData = JSON.parse(response.data.data.notify_settings);

      let result = Object.entries(notifyData).map(([k, v]) => ({ [k]: v }));
      result.forEach((item) => {
        var key = Object.keys(item)[0];
        setstartsFrom(item[key].start_date);
        setIntervals(item[key].interval)

      });
    }).catch((error) => { console.error(error) });
  }


  function changeWeek(value) {
    setIntervals(value);
  }

  const onChange = (date, dateString) => {
   setstartsFrom(dateString);
  };


  const submitSettings = (e) => {


    if (interval == "") {
      Swal.fire("Warning", "Please select a notification interval.", "warning");
    } else if (startsFrom == "") {
      Swal.fire("Warning", "Please select a notification start date.", "warning");
    } else {

      var json_string = '{"0":{"interval":"' + interval + '","start_date":"' + startsFrom + '"}}';

      const data_json = { compliance_type: id, notify_settings: json_string }
      submitSettingDetails(data_json).then((response) => {
        if (response.data.status_code == 1) {
          Swal.fire("Success", "" + response.data.message, "success").then(function () {
            loadSettingsData();
          });
        } else {
          Swal.fire("Warning", " " + response.data.message, "warning");
        }
      }).catch((error) => {
        Swal.fire("Warning", "" + error.response.data.message, "warning");
      });
    }
  }

  return (
    <>
      <section id="simple-table">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-12" ><h5>Notification Schedule Settings</h5></div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center" >
                        <div className="col-md-5"><h6>Starts From:</h6> </div>
                        <div className="col-md-7">
                        <DatePicker style={{ width: '100%' }} value={startsFrom !== "" ? moment(startsFrom) : null} onChange={onChange} />
                        </div>
                      </div>
                      <div className="col-md-12 p-1 d-flex justify-content-between align-items-center">
                        <div className="col-md-5"><h6>Interval:</h6> </div>
                        <div className="col-md-7">
                          <Select className="form-control" value={interval} style={{ width: '100%' }} onChange={changeWeek}>
                            <Option value="1w">1 Week</Option>
                            <Option value="2w">2 Weeks</Option>
                            <Option value="3w">3 Weeks</Option>
                          </Select>
                        </div>
                      </div>


                      <div className="col-md-12 p-1 d-flex justify-content-end align-items-center">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm float-right"
                            onClick={(e) => submitSettings(e)}
                          >
                            <i class="fa fa-check"></i> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CCTVLogSetting;
