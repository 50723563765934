import $ from "jquery";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { adminUrl, APIEndpoint } from "./../UrlPaths";
import styled from "styled-components";
import PerfectScrollbar from 'perfect-scrollbar';

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #252831;
    border-left: 4px solid #f7922f;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #252831;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    background: green;
    cursor: pointer;
  }
`;

function Sidebar() {
  const user_role = localStorage.getItem("hms_user_role");
  const [mainMenus, setMenus] = useState([]);
  const [baseMenuId, setBaseMenuId] = useState();
  //const [subMenus, setOpenMenus] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const token = localStorage.getItem("hms_access_token");

  const [logoPath, setLogoPath] = useState(localStorage.getItem("hms_company_logo"));
  const [subnav, setSubnav] = useState(false);

  const showSubnav = (base_menu_id) => {
    const subMenuNav = document.getElementById(
      "subMenuOpenable" + base_menu_id
    );

    var check_open_or_not = subMenuNav.classList.contains("open");
    if (check_open_or_not) {
      subMenuNav.classList.remove("open");
    } else {
      subMenuNav.classList.add("open");
    }
  };

  var menusArray = [];
  var subMenusArray = [];

  const data = {
    user_role,
  };
  useEffect(() => {
    const ps = new PerfectScrollbar('.sidebar-content');
    ps.update();
    getMenus();
  }, []);
  const getMenus = async () => {
    const fetch = async () => {
      try {
        const res = await axios.post(APIEndpoint + "/getGrantedMenus", data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        var index = 0;
        $.each(res.data.data.main_menu, function (k, v) {
          if (v.is_openable === 0) {
            menusArray.push(res.data.data.main_menu[k]);
          } else if (v.is_openable === 1) {
            var base_id = v.id;
            menusArray.push(res.data.data.main_menu[k]);

            $.each(res.data.data.access_menu, function (key, i) {
              var parent_id = i.parent_id;

              if (base_id == parent_id) {
                subMenusArray.push(res.data.data.access_menu[key]);
              }
            });
          }
        });
        setMenus(menusArray);
        setSubMenus(subMenusArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  };

  return (
    <>
      <div
        className="app-sidebar menu-fixed"
        data-background-color="man-of-steel"
        data-scroll-to-active="true"
      >
        <div className="sidebar-header">
          <div className="logo clearfix">
            <a className="logo-text">
          
              <div className="logo-img text-center">
                <img
                  src={logoPath == "" ? "../../../app-assets/img/archnix-dark3.png" :`${adminUrl}${logoPath}`}
                  // src="../../../app-assets/img/archnix-dark3.png"
                  alt="Logo"
                  width={190}
                //  style={{width:'100%',height:'100%'}}
                />     
              </div>
              
            </a>
            <a
              className="nav-close d-block d-lg-block d-xl-none"
              id="sidebarClose"
              href="javascript:;"
            >
              <i className="ft-x"></i>
            </a>
          </div>
        </div>
        <div className="sidebar-content main-menu-content">
          <div className="nav-container">
            <ul
              className="navigation navigation-main"
              id="main-menu-navigation"
              data-menu="menu-navigation"
            >
              {mainMenus.map((data) => {
                var base_id = data.id;
                return (
                  <>
                    {data.is_openable == "0" ? (
                      <li className="nav-item">
                        <SidebarLink to={`/${data.href}`}>
                          <i class={data.icon}></i>
                          <span
                            className="menu-title"
                            data-i18n={data.display_name}
                          >
                            {data.display_name}
                          </span>
                        </SidebarLink>
                      </li>
                    ) : (
                      <li
                        className="has-sub nav-item"
                        id={`subMenuOpenable${base_id}`}
                      >
                        <SidebarLink
                          to="#!"
                          onClick={() => showSubnav(base_id)}
                        >
                          {/* <a href="javascript:;"> */}
                          <i class={data.icon}></i>
                          <span
                            className="menu-title"
                            data-i18n={data.display_name}
                          >
                            {" "}
                            {data.display_name}
                          </span>
                        </SidebarLink>
                        <ul className="menu-content">
                          {subMenus.map((data) => {
                            return (
                              <>
                                {data.is_main_menu == "0" &&
                                base_id == data.parent_id ? (
                                  <li>
                                    <Link to={`/${data.href}`}>
                                      <i class="ft-arrow-right submenu-icon"></i>
                                      <span
                                        className="menu-item"
                                        data-i18n={data.display_name}
                                      >
                                        {data.display_name}{" "}
                                      </span>
                                    </Link>{" "}
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </ul>
                      </li>
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="sidebar-background"></div>
      </div>
    </>
  );
}

export default Sidebar;
