import React from 'react';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --ant-primary-color: ${({ theme }) => theme.primaryColor};
    --ant-link-color: ${({ theme }) => theme.linkColor};
    --ant-success-color: ${({ theme }) => theme.successColor};
    --ant-warning-color: ${({ theme }) => theme.warningColor};
    --ant-error-color: ${({ theme }) => theme.errorColor};
    --ant-font-size-base: ${({ theme }) => theme.fontSizeBase};
    --ant-heading-color: ${({ theme }) => theme.headingColor};
    --ant-text-color: ${({ theme }) => theme.textColor};
    --ant-text-color-secondary: ${({ theme }) => theme.textColorSecondary};
    --ant-disabled-color: ${({ theme }) => theme.disabledColor};
    --ant-border-radius-base: ${({ theme }) => theme.borderRadiusBase};
    --ant-border-color-base: ${({ theme }) => theme.borderColorBase};
    --ant-box-shadow-base: ${({ theme }) => theme.boxShadowBase};
    --ant-wave-shadow-color: ${({ theme }) => theme.primaryColor};
    --ant-text-hover-color: ${({ theme }) => theme.hoverTextColor};
  }

  // Apply the variables to Ant Design classes
  .ant-spin-dot-item {
    background-color: var(--ant-primary-color);
  }
    .ant-input:focus, .ant-input-focused {
     box-shadow: var(--ant-box-shadow-base);
    }
  .ant-btn-primary {
    background-color: var(--ant-primary-color);
    border-color: var(--ant-primary-color);
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color:  var(--ant-primary-color);
    border-color: darken(var(--ant-primary-color), 10%);
     color: #ffff !important;
  }
    .ant-btn:hover, .ant-btn:focus {
    border-color: var(--ant-primary-color);
    color: var(--ant-primary-color);
    }
  .ant-picker:hover, .ant-picker-focused {
    border-color: var(--ant-primary-color);
  }
   .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: var(--ant-primary-color);
    }

    .ant-select ant-select-focused ant-select- {
        box-shadow: var(--ant-box-shadow-base);
    }
    .ant-picker:focus, .ant-picker:active, .ant-picker-focused {
        border-color: var(--ant-primary-color) !important;
        box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.2) !important;
    }
    .ant-picker-range .ant-picker-active-bar {
        background-color: var(--ant-primary-color);
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border: 1px solid var(--ant-primary-color);
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background-color: var(--ant-primary-color);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background-color: var(--ant-primary-color);
    }
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
        background: var(--ant-primary-color);
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: var(--ant-primary-color);
    }
    .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
     border-color: var(--ant-primary-color);
    }
  // Add more custom styles if needed
`;

const defaultTheme = {
    primaryColor: '#F7922F',
    linkColor: '#F7922F',
    successColor: '#52c41a',
    warningColor: '#faad14',
    errorColor: '#f5222d',
    fontSizeBase: '14px',
    headingColor: 'rgba(0, 0, 0, 0.85)',
    textColor: 'rgba(0, 0, 0, 0.65)',
    textColorSecondary: 'rgba(0, 0, 0, 0.45)',
    disabledColor: 'rgba(0, 0, 0, 0.25)',
    borderRadiusBase: '4px',
    borderColorBase: '#d9d9d9',
    boxShadowBase: '0 2px 8px rgba(0, 0, 0, 0.15)',
    hoverTextColor:'#fffff'
};

export const ThemeProvider = ({ children, theme = defaultTheme }) => (
    <StyledThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
    </StyledThemeProvider>
);