import moment from "moment";
import { adminUrl, websiteUrl } from "../UrlPaths";

const popupDocument = (url) => {
    let win = window.open(
        url,
        null,
        "popup,width=500,height=600,left=0,right=0"
    );
};

export const EducationColumns = [
    {
        key: "degree",
        title: "Degree",
        dataIndex: "degree",
    },
    {
        key: "institution",
        title: "Institution",
        dataIndex: "institution",
    },
    {
        key: "major",
        title: "Major",
        dataIndex: "major",
    },
    {
        key: "year",
        title: "Year",
        dataIndex: "year",
    },
];

export const CertificationColumns = [
    {
        key: "institution",
        title: "Institution",
        dataIndex: "institution",
    },
    {
        key: "name",
        title: "Name",
        dataIndex: "name",
    },
    {
        key: "description",
        title: "Description",
        dataIndex: "description",
    },
    {
        key: "issuedDate",
        title: "Issued Date",
        dataIndex: "issuedDate",
        render: (record) => record != "" ?moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "expDate",
        title: "Exp Date",
        dataIndex: "expDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
];

export const PassportColumns = [
    {
        key: "number",
        title: "Number",
        dataIndex: "number",
    },
    {
        key: "issuedCountry",
        title: "Country of issue",
        dataIndex: "issuedCountry",
    },
    {
        key: "issuedDate",
        title: "Issued Date",
        dataIndex: "issuedDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "expDate",
        title: "Exp Date",
        dataIndex: "expDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "currentActive",
        title: "Current Active",
        dataIndex: "currentActive",
    },
];

export const VisaColumns = [
    {
        key: "number",
        title: "Number",
        dataIndex: "number",
    },
    {
        key: "issuedCountry",
        title: "Country of issue",
        dataIndex: "issuedCountry",
    },
    {
        key: "visaType",
        title: "Visa Type",
        dataIndex: "visaType",
    },
    {
        key: "issuedDate",
        title: "Issued Date",
        dataIndex: "issuedDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "expDate",
        title: "Exp Date",
        dataIndex: "expDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "currentActive",
        title: "Current Active",
        dataIndex: "currentActive",
    },
];

export const COSColumns = [
    {
        key: "licenceNo",
        title: "Licence No",
        dataIndex: "licenceNo",
    },
    {
        key: "sponsorName",
        title: "Sponsor Name",
        dataIndex: "sponsorName",
    },
    {
        key: "certificate",
        title: "Certificate",
        render: (record) => {
            return (
                <>
                  <div className="flex">
                    <span>No  : {record.certificateNo}</span><br/>
                    <span>Date: {record.certificateDate != "" ? moment(record.certificateDate).format("DD/MM/YYYY"):''}</span>
                  </div>
                </>
            );
          },
    },
    // {
    //     key: "certificateDate",
    //     title: "certificateDate",
    //     dataIndex: "certificateDate",
    //     render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    // },
    {
        key: "assignedDate",
        title: "Assigned Date",
        dataIndex: "assignedDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "expDate",
        title: "Exp Date",
        dataIndex: "expDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    { 
        key: "sponsorNote",
        title: "Sponsor Note",
        dataIndex: "sponsorNote",
    },
    { 
        key: "attachment",
        title: "  ",
        width: '8%',
        render: (record) => {
            return (
                <>
                  <div className="flex">
                  <div onClick={() => popupDocument(`${adminUrl}${record.attachment}`)} style={{ cursor: 'pointer' }}>
                    <img src={`${websiteUrl}app-assets/img/pdf-format.png`} alt="image"  className="img-fluid" />
                    </div>
                  </div>
                </>
            );
          },
    },
];

export const RTWColumns = [
    {
        key: "rtwRef",
        title: "RTW Ref",
        dataIndex: "rtwRef",
    },
    {
        key: "checkDate",
        title: "Date of check",
        dataIndex: "checkDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    {
        key: "expDate",
        title: "Exp Date",
        dataIndex: "expDate",
        render: (record) => record != "" ? moment(record).format("DD/MM/YYYY"):'',
    },
    { 
        key: "status",
        title: "RTW Status",
        dataIndex: "status",
    },
    { 
        key: "attachment",
        title: "",
        width: '8%',
        render: (record) => {
            return (
                <>
                  <div className="flex">
                  <div onClick={() => popupDocument(`${adminUrl}${record.attachment}`)} style={{ cursor: 'pointer' }}>
                    <img src={`${websiteUrl}app-assets/img/pdf-format.png`} alt="image" className="img-fluid" />
                    </div>
                  </div>
                </>
            );
          },
    },
];