import { Table } from "antd";
function EmployeeOtherTable({ dataset,type ,tableLoading, columnsSet, openModal }) {
  const newColumn = {
    key: "action",
    title: "",
    width: '6%',
    render: (record) => {
      return (
        <>
          <div className="flex">
            <button
              className="badge badge-pill bg-light-danger action-btn"
              style={{ color: "black" }}
              onClick={() => openModal(type, 'Edit', record)}
            >
              <i class="ft-edit"></i>
            </button>
          </div>
        </>
      );
    },
  }
  const columns = [...columnsSet, newColumn]
  return (
    <div className="row mt-2">
      <div className="col-md-12 col-12">
        <div className="table-responsive">
          <div className="table">
            <Table columns={columns} dataSource={dataset}
              pagination={{
                pageSize: 5
              }}
              loading={tableLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeOtherTable;
